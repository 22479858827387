import { useNavigate } from 'react-router-dom';

import { TopbarButtonType } from '../ui/HeaderDesktop';

const useDropdownItem = ({
  onHandleClick,
  button,
}: {
  button: TopbarButtonType;
  onHandleClick: () => void;
}) => {
  const size = button?.size ?? 'normal';
  const navigate = useNavigate();

  const handleClick = () => {
    onHandleClick();
    if (button?.onClick) {
      button.onClick();
    }
    if (button?.path) {
      navigate(button?.path);
    }
  };
  return { handleClick, size };
};
export default useDropdownItem;

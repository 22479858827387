import { VehicleTypesEnum } from '../types/enums';
import { ManageVehicleStoreActionType } from '../types/storeActions';

type manageVehicleStoreType = {
  selectedVehicleType: VehicleTypesEnum;
  addedVehicleType: VehicleTypesEnum;
};

export const initialVehicleState: manageVehicleStoreType = {
  selectedVehicleType: VehicleTypesEnum.MOTOR_VEHICLE,
  addedVehicleType: VehicleTypesEnum.MOTOR_VEHICLE,
};

const manageVehicleStore = (
  state = initialVehicleState,
  action: ManageVehicleStoreActionType
) => {
  switch (action.type) {
    case 'SET_SELECTED_VEHICLE_TYPE':
      return {
        ...state,
        selectedVehicleType: action.payload,
      };
    case 'SET_ADDED_VEHICLE_TYPE':
      return {
        ...state,
        addedVehicleType: action.payload,
      };
    default:
      return state;
  }
};

export default manageVehicleStore;

import { SentimentVeryDissatisfied } from '@mui/icons-material';
import { t } from 'i18next';
import React from 'react';

const KdNoResults: React.FC = () => (
  <div className="flex mt-[10%] justify-center">
    <SentimentVeryDissatisfied className="mr-[5px]" />
    <span className="text-textXs sm:text-textBase text-contentColor italic">
      {t('No results found. Try adjusting your search.')}
    </span>
  </div>
);

export default KdNoResults;

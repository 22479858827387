import cn from 'classnames';

import { FuelTypesEnum } from '../types/enums';
import { VehicleType } from '../types/vehicle';

type Props = {
  /** The additional class name of the VRM plate */
  className?: string;
  /** Determines if the VRM plate is selected */
  isSelected?: boolean;
  /** The onClick handler */
  onClick?: () => void;
  /** The VRM plate styling variant, it can be "yellow" and "white" */
  variant?: 'yellow' | 'white';
  /** The vehicle object */
  vehicle: VehicleType;
};

const maxVrmCharacters = 8;

const KdVRMPlate = ({
  className,
  isSelected,
  onClick,
  variant = 'yellow',
  vehicle,
}: Props) => {
  const isZeroEmissionsBay = vehicle?.fuel_type === FuelTypesEnum.ELECTRICITY;

  const isYellowPlate = variant === 'yellow' || isSelected;
  const isWhitePlate = variant === 'white';

  const isVrmLengthLong = vehicle?.vrm?.length > maxVrmCharacters;

  const classes = cn(
    'flex items-center justify-center h-[38px] sm:h-[45px] w-[132px] sm:w-[155px] px-[10px] bg-no-repeat bg-cover',
    isZeroEmissionsBay
      ? {
          'bg-[url("../assets/images/vrmPlateEV.svg")] pl-[23px]':
            isYellowPlate,
          'bg-[url("../assets/images/vrmPlateWhiteEV.svg")] pl-[23px]':
            isWhitePlate,
        }
      : {
          'bg-[url("../assets/images/vrmPlate.svg")]': isYellowPlate,
          'bg-[url("../assets/images/vrmPlateWhite.svg")]': isWhitePlate,
        },
    className
  );

  const textClasses = cn(
    'inline-block text-[22px] sm:text-[25px] text-accentSecondary font-number font-extrabold',
    {
      'max-w-[112px] truncate': isVrmLengthLong,
    }
  );

  return (
    <div
      className={classes}
      data-testid="vrm-plate"
      onClick={onClick}
      role="button"
      tabIndex={0}
      title={isVrmLengthLong ? vehicle?.vrm : undefined}
    >
      <span className={textClasses}>{vehicle?.vrm}</span>
    </div>
  );
};
export default KdVRMPlate;

import React from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';

import KdCarouselArrow from '../KdCarouselArrow';
import useKdCarousel from '../hooks/useKdCarousel';

type Props = {
  activeSlideIndex?: number;
  children: React.ReactNode;
  className?: string;
  nextClassName?: string;
  prevClassName?: string;
  settings?: Settings;
};

const defaultSettings = {
  accessibility: true,
  arrow: true,
  dots: false,
  focusOnSelect: false,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  touchMove: true,
};

const KdCarousel: React.FC<Props> = ({
  activeSlideIndex,
  children,
  className,
  nextClassName,
  prevClassName,
  settings,
}) => {
  const { setSlider } = useKdCarousel({ activeSlideIndex });

  return (
    <Slider
      {...defaultSettings}
      {...settings}
      ref={(s) => setSlider(s)}
      className={className}
      nextArrow={
        <KdCarouselArrow
          arrowClassName={nextClassName}
          variant="next"
        />
      }
      prevArrow={<KdCarouselArrow arrowClassName={prevClassName} />}
    >
      {children}
    </Slider>
  );
};

export default KdCarousel;

/* eslint-disable @typescript-eslint/no-explicit-any */
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';

import useModal from '../../../hooks/useModal';
import KdCarousel from '../../../shared/Carousel/ui/KdCarousel';
import KdDayRangePicker from '../../../shared/DayPicker/KdDayRangePicker';
import KdDaySinglePicker from '../../../shared/DayPicker/KdDaySinglePicker';
import KdDayPickerCaption from '../../../shared/DayPicker/ui/KdDayPickerCaption';
import KdBreadcrumbs from '../../../shared/KdBreadcrumbs';
import KdButton from '../../../shared/KdButton';
import KdNoResults from '../../../shared/KdNoResults';
import KdPageTitle from '../../../shared/KdPageTitle';
import KdSwitch from '../../../shared/KdSwitch';
import KdTooltip from '../../../shared/KdTooltip/KdTooltip';
import KdVRMPlate from '../../../shared/KdVRMPlate';
import KdModal from '../../../shared/Modals/KdModal';
import MessageModal from '../../../shared/Modals/MessageModal';
import KdPaging from '../../../shared/Paging/ui/KdPaging';
import { FuelTypesEnum } from '../../../types/enums';
import { KdSwitchValueType } from '../../../types/kdSwitchValue';

const pagingData = [
  { id: 'vfvfv', title: 'Item 1' },
  { id: 'nyhnn', title: 'Item 2' },
];

const links = [{ text: 'Bookings', url: '/bookings' }];

const vehicle = {
  id: 'd4d7a891-31c9-4b76-8f07-4b56628aa102',
  owner_id: 'e9d212ee-485f-4b60-890e-e1ca8ad68196',
  vrm: 'AAARNSGL',
};

const vehicleEV = {
  fuel_type: FuelTypesEnum.ELECTRICITY,
  id: 'd4d7a891-31c9-4b76-8f07-4b56628aa102',
  owner_id: 'e9d212ee-485f-4b60-890e-e1ca8ad68196',
  vrm: 'AAARNSGL',
};

const GenericComponents2 = () => {
  const [switchValue, setSwitchValue] = useState<KdSwitchValueType>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [isOpenPicker, setIsOpenPicker] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [isOpenRangePicker, setIsOpenRangePicker] = useState<boolean>(false);
  const [range, setRange] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  });

  const { closeModal, show, showModal } = useModal();
  const {
    closeModal: closeWarningModal,
    show: showWarning,
    showModal: showWarningModal,
  } = useModal();
  const {
    closeModal: closeDarkModal,
    show: showDark,
    showModal: showDarkModal,
  } = useModal();

  const handleSwitch = (value: KdSwitchValueType) => {
    setSwitchValue(value);
  };

  const handleClick = () => {
    setIsOpenPicker(!isOpenPicker);
  };

  const handleRangeClick = () => {
    setIsOpenRangePicker(!isOpenRangePicker);
  };

  const handleDayClick = (date: Date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Switch:
        </Typography>
        <KdSwitch
          className="mb-[15px] w-[500px]"
          firstText="Bay Bookings"
          onClick={handleSwitch}
          secondText="Zonal Permits"
          value={switchValue}
        />
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Page title:
        </Typography>
        <KdPageTitle
          pageTitle="Page title"
          description="page description text"
        />
      </div>
      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Breadcrumbs:
        </Typography>
        <div className="flex items-center mb-[20px]">
          <div className="w-[160px]">Default:</div>
          <KdBreadcrumbs
            lastItemText="Last item"
            links={links}
          />
        </div>
        <div className="flex items-center">
          <div className="w-[160px]">Small:</div>
          <KdBreadcrumbs
            lastItemText="Last item"
            links={links}
            size="small"
          />
        </div>
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Tooltip:
        </Typography>
        <KdTooltip
          id="id"
          buttonContent={
            <KdButton size="small">My tooltip (hover on me)</KdButton>
          }
        >
          Tooltip content
        </KdTooltip>
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Modals:
        </Typography>
        <KdButton
          size="small"
          onClick={showModal}
        >
          Base modal
        </KdButton>
        <KdButton
          size="small"
          variant="secondary"
          onClick={showDarkModal}
          className="ml-[20px]"
        >
          Dark modal
        </KdButton>
        <KdButton
          size="small"
          variant="tertiary"
          onClick={showWarningModal}
          className="ml-[20px]"
        >
          White warning modal
        </KdButton>
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          No Search Results:
        </Typography>
        <div className="max-w-[500px]">
          <KdNoResults />
        </div>
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          VRMs:
        </Typography>
        <div className="max-w-[500px]">
          <KdVRMPlate
            className="mb-[20px]"
            vehicle={vehicle}
          />
          <KdVRMPlate
            className="mb-[20px]"
            vehicle={vehicleEV}
          />
          <KdVRMPlate
            className="mb-[20px]"
            vehicle={vehicle}
            variant="white"
          />
          <KdVRMPlate
            vehicle={vehicleEV}
            variant="white"
          />
        </div>
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          DayPickers:
        </Typography>
        <div className="flex mb-[20px]">
          <div className="w-[160px] pt-[20px]">Single date:</div>
          <div className="w-[470px]">
            <KdDayPickerCaption
              isDropdownOpen={isOpenPicker}
              onClick={handleClick}
              selectedDate={selectedDate!}
            />
            {isOpenPicker && (
              <KdDaySinglePicker
                onDayClick={handleDayClick}
                selected={selectedDate!}
              />
            )}
          </div>
        </div>
        <div className="flex mb-[20px]">
          <div className="w-[160px] pt-[20px]">Range dates:</div>
          <div className="w-[470px]">
            <KdDayPickerCaption
              isDropdownOpen={isOpenRangePicker}
              onClick={handleRangeClick}
              range={range}
            />
            {isOpenRangePicker && (
              <KdDayRangePicker
                onSelect={setRange}
                selected={range}
              />
            )}
          </div>
        </div>
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Carousel:
        </Typography>
        <div className="max-w-[600px]">
          <KdCarousel>
            <div>carousel item 1</div>
            <div>carousel item 2</div>
            <div>carousel item 3</div>
          </KdCarousel>
        </div>
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Paging:
        </Typography>
        <KdPaging
          className="max-w-[600px]"
          data={pagingData}
          totalPages={Math.ceil(pagingData.length / 24)}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          title="Paging title"
          renderItems={(items) => (
            <div className="flex justify-between flex-wrap mx-[-30px] 2xl:mx-[-15px] my-[-20px] min-h-[120px]">
              {items.map((item: any) => (
                <div
                  key={item.id}
                  className="mx-[30px] 2xl:mx-[15px] my-[20px] w-[calc(50%-60px)] 2xl:w-[calc(33.333%-30px)] 2xl:max-w-[470px]"
                >
                  {item.title}
                </div>
              ))}
            </div>
          )}
        />
      </div>

      {/* Modals */}
      <KdModal
        onClose={closeModal}
        show={show}
      >
        <Typography
          className="text-[20px] sm:text-[28px] sm:text-center mb-[30px]"
          component="h4"
          variant="h4"
        >
          Base Modal title
        </Typography>
        <div>Base Modal content</div>
      </KdModal>

      <KdModal
        onClose={closeDarkModal}
        show={showDark}
        variant="dark"
      >
        <Typography
          className="text-[20px] sm:text-[28px] sm:text-center mb-[30px]"
          component="h4"
          variant="h4"
        >
          Modal title
        </Typography>
        <div>Modal content</div>
      </KdModal>

      <MessageModal
        description="Warning modal description"
        subDescription="Warning modal sub-description"
        show={showWarning}
        title="Warning title"
        testId=""
        onClose={closeWarningModal}
        actions={
          <KdButton
            onClick={closeWarningModal}
            type="button"
          >
            Go back
          </KdButton>
        }
      />
    </>
  );
};

export default GenericComponents2;

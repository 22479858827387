import useWindowDimensions from '../../hooks/useWindowDimensions';
import HeaderDesktop from './ui/HeaderDesktop';
import HeaderMobile from './ui/HeaderMobile';

type TopBarPropsType = {
  pageTitle: string;
};

const TopBar = ({ pageTitle }: TopBarPropsType): JSX.Element => {
  const { isMobile } = useWindowDimensions();

  return isMobile ? <HeaderMobile pageTitle={pageTitle} /> : <HeaderDesktop />;
};

export default TopBar;

import React from 'react';
import { useTranslation } from 'react-i18next';

const schemaFiles =
  require(`../../theme/${process.env.REACT_APP_THEME}/schemaFiles.tsx`).default;

const WarningExclamation = ({ className }: { className?: string }) => {
  const { t } = useTranslation();

  return (
    <schemaFiles.images.alertIcon
      className={className}
      alt={t('Delete warning')}
      data-testid="warning-exclamation-svg"
    />
  );
};

export default WarningExclamation;

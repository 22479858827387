import React from 'react';

import ErrorPage from './pages/ErrorPage/ErrorPage';

type Props = {
  children?: React.ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Object, errorInfo: Object) {
    // eslint-disable-next-line no-console
    console.error(`[Error]: ${error}, ${errorInfo}`);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;

import Joi from 'joi';

import { regexp } from '../../data/regexp';

export const updateOrganisationScheme = Joi.object({
  id: Joi.string()
    .required()
    .pattern(regexp.id)
    .label('Organisation ID')
    .messages({
      'string.pattern.base': '{{#label}} is invalid',
    }),
});

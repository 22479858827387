import { UserTypeEnum } from '../../types/enums';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  rejectRequest,
} from '../fetch';
import { CreateUserRequestType, UpdateUserRequestType } from '../types';
import {
  checkEmailScheme,
  createUserScheme,
  getUsersScheme,
  updateUserScheme,
  userScheme,
} from '../validation';

export const callGetUsersByGroup = ({
  userGroup,
}: {
  userGroup: UserTypeEnum;
}) => {
  const { error } = getUsersScheme.validate({ userGroup });

  if (error) {
    return rejectRequest(error?.message);
  }

  const query = userGroup === undefined ? '' : `?user_group=${userGroup}`;

  return getRequest(`/users${query}`);
};

export const callGetUser = ({ id }: { id: string }) => {
  const { error } = userScheme.validate({ id });

  if (error) {
    return rejectRequest(error?.message);
  }

  return getRequest(`/users/${id}`);
};

export const callAddUser = ({
  fullName,
  userType,
  temporaryPassword,
  email,
  number,
}: CreateUserRequestType) => {
  const { error } = createUserScheme.validate({
    fullName,
    userType,
    temporaryPassword,
    email,
  });

  if (error) {
    return rejectRequest(error?.message);
  }

  return postRequest('/users', {
    user_name: fullName,
    user_group: userType,
    temporary_password: temporaryPassword,
    email: email?.toLowerCase(),
    phone: number,
  });
};

export const callPatchUser = ({ id, name }: UpdateUserRequestType) => {
  const { error } = updateUserScheme.validate({ id, name });

  if (error) {
    return rejectRequest(error?.message);
  }

  return patchRequest(`/users/${id}`, { name });
};

export const callDeleteUser = ({ id }: { id: string }) => {
  const { error } = userScheme.validate({ id });

  if (error) {
    return rejectRequest(error?.message);
  }

  return deleteRequest(`/users/${id}`);
};

export const callCheckUserEmail = ({ email }: { email: string }) => {
  const { error } = checkEmailScheme.validate({ email });

  if (error) {
    return rejectRequest(error?.message);
  }

  return getRequest(`/registration/${email}/whitelist`);
};

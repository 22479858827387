import Joi from 'joi';

import { regexp } from '../../data/regexp';
import { FuelTypesEnum, VehicleTypesEnum } from '../../types/enums';

export const getVehiclesScheme = Joi.object({
  numberOfVehicles: Joi.number().integer().label('Vehicles Number'),
  pageNumber: Joi.number().integer().label('Page Number'),
  searchValue: Joi.string()
    .allow(null, '')
    .pattern(regexp.text)
    .label('Search Value')
    .messages({
      'string.pattern.base': '{{#label}} is invalid',
    }),
  vehicleType: Joi.string()
    .valid(...Object.values(VehicleTypesEnum))
    .label('Vehicle Type')
    .messages({
      'any.only': '{{#label}} is invalid',
    }),
  fuelType: Joi.string()
    .valid(...Object.values(FuelTypesEnum))
    .label('Fuel Type')
    .messages({
      'any.only': '{{#label}} is invalid',
    }),
});

export const vehicleScheme = Joi.object({
  id: Joi.string().required().pattern(regexp.id).label('Vehicle ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
});

export const createVehicleScheme = Joi.object({
  vrm: Joi.string()
    .required()
    .pattern(regexp.vrm)
    .label('VRM number')
    .messages({
      'string.pattern.base': '{{#label}} is invalid',
    }),
  vehicleType: Joi.string()
    .valid(...Object.values(VehicleTypesEnum))
    .label('Vehicle Type')
    .messages({
      'any.only': '{{#label}} is invalid',
    }),
});

export const updateVehicleScheme = Joi.object({
  id: Joi.string().required().pattern(regexp.id).label('Vehicle ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
  vrm: Joi.string()
    .required()
    .pattern(regexp.vrm)
    .label('VRM number')
    .messages({
      'string.pattern.base': '{{#label}} is invalid',
    }),
});

import cn from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
  isActive?: boolean;
  isDefaultMenuValue?: boolean;
  label: string;
  testid?: string;
}

const KdDropdownItem: React.FC<Props> = ({
  className,
  isActive,
  isDefaultMenuValue,
  label,
  onClick,
  testid,
}) => {
  const classes = cn(
    'rounded-base text-controlColour hover:bg-accentSecondary focus:bg-accentSecondary px-[10px] py-[5px] mx-[-10px] w-[calc(100%+20px)] text-left text-textXs',
    {
      'bg-accentSecondary': isActive || isDefaultMenuValue,
    },
    className
  );

  return (
    <button
      className={classes}
      onClick={onClick}
      type="button"
      data-testid={testid}
    >
      {label}
    </button>
  );
};

export default KdDropdownItem;

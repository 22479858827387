import { ExpandLess, ExpandMore } from '@mui/icons-material';
import cn from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  disabled?: boolean;
  id?: string;
  isArrowHidden?: boolean;
  isReadOnly?: boolean;
  onClick: () => void;
  show: boolean;
  value: string;
}

const KdToggleButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      disabled,
      id,
      isArrowHidden,
      isReadOnly,
      onClick,
      show,
      value,
    },
    ref
  ) => {
    const classes = cn(
      'flex justify-between items-center border-controlWidth w-full rounded-base text-controlColour h-mobileCotrol sm:h-control px-[20px]',
      isReadOnly
        ? 'border-borderColor sm:border-transparent bg-transparent'
        : 'border-borderColor',
      { 'cursor-pointer': !isReadOnly && !disabled },
      className
    );

    const arrowClasses = cn(
      'text-[32px] text-borderColor mr-[-12px] mt-[-4px] pointer-events-none',
      { hidden: isArrowHidden }
    );

    return (
      <button
        className={classes}
        disabled={disabled || isReadOnly}
        id={id}
        onClick={onClick}
        ref={ref}
        type="button"
      >
        <span className="text-textXs sm:text-textBase text-contentColor truncate">
          {value}
        </span>
        {!isReadOnly && (
          <span
            className={arrowClasses}
            data-testid="dropdown-arrows"
          >
            {show ? (
              <ExpandLess
                fontSize="inherit"
                color="inherit"
              />
            ) : (
              <ExpandMore
                fontSize="inherit"
                color="inherit"
              />
            )}
          </span>
        )}
      </button>
    );
  }
);

export default KdToggleButton;

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import KdButton from '../../../shared/KdButton';
// import { SchemaInfo } from '../../../types/schema';
// import FeatureFlagWrapper from '../../../wrappers/FeatureFlagWrapper';
import useHelpFinished from '../hooks/useHelpFinished';

import { ReactComponent as HelpFinishedCheck } from '../../../assets/images/helpfinished-check.svg';

// const schemaInfo: SchemaInfo = require(`./../../../theme/${process.env.REACT_APP_THEME}/schemaInfo.json`);

const HelpFinished = () => {
  const { t } = useTranslation();
  const { onClick } = useHelpFinished();

  return (
    <div className="form__form-inner help-view__finished text-center" data-testid="helpfinished-container">
      <Typography
        variant="h4"
        component="h4"
        gutterBottom
      >
        {t('Help is on the way!')}
      </Typography>

      <div className="help-view__checkimage-container" data-testid="green-check">
        <HelpFinishedCheck />
      </div>

      <Typography
        variant="h6"
        component="h6"
        gutterBottom
      >
        <div className="help-view__thankyou-message">
          <span className="text-center"
                data-testid="thank-you-text">{t('Thank you for submitting your help request.')}</span>
          <br />
          <span data-testid="get-back-text">{t('We aim to get back to you within 2 working days.')}</span>
        </div>
      </Typography>

      <div className="help-view__support-email --italic text-white mb-5" data-testid="support-email-text">
        <p className="help-view__open-hours" data-testid="support-openhours-text">{t('Support is available Monday - Friday, 09:00 - 17:00 BST')}</p>
      </div>

      <KdButton
        type="submit"
        onClick={onClick}
        testId="submit-button"
        fullOnMobile
      >
        {t('Return to Home')}
      </KdButton>

    </div>
  );
};
export default HelpFinished;

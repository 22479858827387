import { ManageBookingsStoreActionType } from '../types/storeActions';
import { ManageBookingStoreType } from '../types/stores';

export const initialManageBookingState: ManageBookingStoreType = {
  selectedBooking: null,
  selectedDate: null,
  newBookingTime: null,
};

const manageBookingsStore = (
  state = initialManageBookingState,
  action: ManageBookingsStoreActionType
) => {
  switch (action.type) {
    case 'SET_SELECTED_BOOKING':
      return {
        ...state,
        selectedBooking: action.payload,
      };
    case 'SET_SELECTED_DATE':
      return {
        ...state,
        selectedDate: action.payload,
      };
    case 'SET_EDIT_BOOKING_TIME':
      return {
        ...state,
        newBookingTime: action.payload,
      };
    default:
      return state;
  }
};

export default manageBookingsStore;

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import React, { SyntheticEvent } from 'react';

interface Props {
  className?: string;
  classNameTitle?: string;
  children: React.ReactNode;
  id?: string;
  isNewDesign?: boolean;
  noVerticalMargin?: boolean;
  onSubmit?: () => void;
  onSubmitEvent?: (e: SyntheticEvent) => void;
  title?: string | React.ReactNode;
}

const FormContainer: React.FC<Props> = ({
  children,
  className,
  classNameTitle,
  id,
  isNewDesign,
  noVerticalMargin,
  onSubmit,
  onSubmitEvent,
  title,
}) => {
  const classes = cn(
    isNewDesign ? 'sm:w-[470px]' : 'sm:w-[540px]',
    'w-full mx-auto',
    { 'my-[30px] sm:my-[50px]': !noVerticalMargin },
    className
  );

  const titleClasses = cn(
    'text-[20px] sm:text-[25px] font-semibold mb-[15px]',
    isNewDesign ? 'text-white' : 'text-accentPrimary',
    classNameTitle
  );

  return (
    <form
      autoComplete="off"
      className={classes}
      id={id}
      noValidate
      onSubmit={onSubmit || onSubmitEvent}
    >
      {title && (
        <Typography
          className={titleClasses}
          component="h1"
          gutterBottom
          variant="h4"
        >
          {title}
        </Typography>
      )}
      {children}
    </form>
  );
};
export default FormContainer;

import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AuthContextType } from '../auth/types/authContextType';
import { RootState } from '../store';
import { UserType } from '../types/common';
import { SchemaInfo } from '../types/schema';
import routesInfo from './routesInfo';

const schemaInfo: SchemaInfo = require(`../theme/${process.env.REACT_APP_THEME}/schemaInfo.json`);

const excludedPathNames = ['/createbooking', '/editbooking'];

const useRouterProvider = () => {
  const location = useLocation();

  const featureFlagStore = useSelector(
    (state: RootState) => state.featureFlagStore
  );

  const intialUserType = localStorage.getItem('userType') as UserType;
  const currentRoute = Object.entries(routesInfo).find(([key]) =>
    location.pathname.includes(key)
  );

  const [authState] = useState<AuthContextType>({
    isLoggedIn: false,
    userType: intialUserType ?? 'CF',
    user: null,
    userGroup: '',
    uiState: {},
    tempName: null,
    isUserRestoreComplete: false,
    restorePathName: null,
  });

  useEffect(() => {
    const viewName = currentRoute?.[0];

    if (viewName) {
      datadogRum.startView({ name: `/${viewName}` || '/' });
    }
  }, [location.pathname, currentRoute]);

  const currentRouteInfo = currentRoute?.[1];
  const pageTitle = currentRouteInfo?.title || schemaInfo.productName;

  const isFeatureFlagId = currentRouteInfo?.featureFlagId;
  const isRouteVisible = isFeatureFlagId
    ? featureFlagStore[isFeatureFlagId]
    : true;

  const isStrictMode = !excludedPathNames.includes(location.pathname);

  return { authState, isRouteVisible, isStrictMode, pageTitle };
};

export default useRouterProvider;

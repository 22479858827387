const EditIcon = ({ fill = '#62777e' }: { fill?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.641"
    height="16.5"
    viewBox="0 0 16.641 16.5"
    fill="currentColor"
  >
    <g
      id="Icon_feather-edit"
      data-name="Icon feather-edit"
      transform="translate(0.75 0.75)"
    >
      <path
        id="Path_3689"
        data-name="Path 3689"
        d="M9.709,6H4.491A1.491,1.491,0,0,0,3,7.491V17.928a1.491,1.491,0,0,0,1.491,1.491H14.928a1.491,1.491,0,0,0,1.491-1.491V12.709"
        transform="translate(-3 -4.419)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_3690"
        data-name="Path 3690"
        d="M19.828,3.281a1.581,1.581,0,0,1,2.236,2.236L14.982,12.6,12,13.345l.745-2.982Z"
        transform="translate(-7.527 -2.818)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export default EditIcon;

import { t } from 'i18next';
import React from 'react';

import KdButton from '../../shared/KdButton';
import useErrorPage from './hooks/useErrorPage';

const ErrorPage: React.FC = () => {
  const { handleReturnHome, handleClearCache } = useErrorPage();

  return (
    <main className="flex justify-center items-center px-[30px] h-[100vh]">
      <div className="text-center">
        <p className="text-[28px] text-content mb-[30px] font-[700]">Oops!</p>
        <p className="text-[25px] text-content mb-[20px]">
          {t('Looks like something went wrong.')}
        </p>
        <div className="text-[16px] sm:text-[20px] text-content mb-[30px] leading-6">
          {t('Clear your cache to try again or return to the homepage.')}
        </div>
        <div className="flex flex-col-reverse sm:flex-row justify-center gap-3">
          <KdButton
            testId="button-return-home"
            onClick={handleReturnHome}
            fullOnMobile
            variant="secondary"
          >
            {t('Return Home')}
          </KdButton>

          <KdButton
            testId="button-clear-cache"
            onClick={handleClearCache}
            fullOnMobile
          >
            {t('Clear Cache')}
          </KdButton>
        </div>
      </div>
    </main>
  );
};

export default ErrorPage;

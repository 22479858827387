import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomErrorType } from '../types/errors';

/**
 * @param {Object} errors checks for badly filled out fields in forms
 * @param {String} errors.type type of the error which determines what message is going to be displayed by errorMessage
 * @param {String} errors.message default
 * @returns {Component} depending on if any errors exists returns a small snippet of text displaying the error
 */
type Props = {
  errors: CustomErrorType;
};

const FormErrorMessage = ({ errors }: Props) => {
  const { t } = useTranslation();
  let errorMessage = '';
  if (errors) {
    switch (errors.type) {
      case 'pattern':
        errorMessage = t('Please match the required field');
        break;
      case 'required':
        errorMessage = t('This field is required');
        break;
      case 'validate':
        errorMessage = t('Passwords Do Not Match');
        break;
      case 'minLength':
        errorMessage = t('Value is too short');
        break;
      case 'maxLength':
        errorMessage = t('Value is too long');
        break;
      default:
        errorMessage = t('This field is required');
        break;
    }

    if (errors.message) errorMessage = errors.message;
  }

  return errors && Object.keys(errors).length > 0 ? (
    <p
      className="bg-warning rounded-b-[5px] text-[12px] text-white mt-[-16px] font-semibold p-[5px] before:content-['⚠'] before:mr-[5px] text-left w-full"
      role="alert"
    >
      <strong>{errorMessage}</strong>
    </p>
  ) : null;
};

export default FormErrorMessage;

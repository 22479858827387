import useMenuMobile from '../hooks/useMenuMobile';
import { MenuProps } from '../types/menuTypes';
import HighlightMobile from './HighlightMobile';
import MenuItem from './MenuItem';
import MenuItemsList, { RenderMenuItem } from './MenuItemsList';
import MenuMoreButton from './MenuMoreButton';

const MenuMobile = ({ menuItems, selectedHighlight, onClick }: MenuProps) => {
  const { isMoreButtonActive, handleMoreClick, itemWidth } = useMenuMobile({
    menuItems,
    selectedHighlight,
  });

  return (
    <nav
      data-testid="bottom-navigation"
      className="fixed bottom-0 inset-x-0 h-mobileMenu bg-secondary shadow px-[10px] z-[2500]"
    >
      <MenuItemsList
        className="flex justify-between items-center h-full"
        menuItems={menuItems}
        renderMenuItems={({ item, index }: RenderMenuItem) => {
          if (index < 4)
            return (
              <MenuItem
                icon={item.img}
                key={`menu-${item.testId}`}
                onClick={() => onClick(item.linkHref)}
                selected={selectedHighlight.upper === index}
                style={{ width: `${itemWidth}px` }}
              />
            );
        }}
        render={(menuItems) => (
          <MenuMoreButton
            itemWidth={itemWidth}
            isMoreButtonActive={isMoreButtonActive}
            menuItems={menuItems}
            onClick={handleMoreClick}
          />
        )}
      />
      <HighlightMobile
        itemWidth={itemWidth}
        isMoreButtonActive={isMoreButtonActive}
        selectedIndex={selectedHighlight.upper}
      />
    </nav>
  );
};

export default MenuMobile;

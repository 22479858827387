import { MenuItemProps, MenuItemsType } from '../types/menuTypes';

export interface RenderMenuItem {
  item: MenuItemProps;
  index: number;
}

interface Props {
  className?: string;
  menuItems: MenuItemsType;
  render?: (menuItems: MenuItemsType) => React.ReactNode;
  renderMenuItems: ({ item, index }: RenderMenuItem) => React.ReactNode;
}

const MenuItemsList = ({
  className,
  menuItems,
  renderMenuItems,
  render,
}: Props) => (
  <div className={className}>
    {menuItems?.map((item, index) => renderMenuItems({ item, index }))}
    {render && render(menuItems)}
  </div>
);

export default MenuItemsList;

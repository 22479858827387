import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

import FormErrorMessage from '../../shared/FormErrorMessage';
import FormContainer from '../../shared/Forms/FormContainer';
import KdButton from '../../shared/KdButton';
import { SchemaInfo } from '../../types/schema';
import useHelpForm from './hooks/useHelpForm';
import { useFormState } from 'react-hook-form';

const schemaInfo: SchemaInfo = require(`./../../theme/${process.env.REACT_APP_THEME}/schemaInfo.json`);

const HelpPage = () => {
  const { t } = useTranslation();

  const { onSubmit, register, handleSubmit, isSubmitting, errors, control } =
    useHelpForm();

  const { isValid } = useFormState({
    control,
  });

  const helpTextFieldValidation = {
    // Rule: Field must not be empty
    required: {
      value: true,
      message: t('This field is required'),
    },
    // Rule: Maximum length of the field is 1000 character
    maxLength: {
      value: 1000,
      message: t('Message must be 1000 characters or shorter'),
    },
    // Rule: Minimum length of the field is 16 characters
    minLength: {
      value: 16,
      message: t('Message must be 16 character or longer'),
    },
  };

  return (
    <div className="flex items-center justify-center min-h-full" data-testid="help-page-container">
      <FormContainer
        className="px-[30px] text-center"
        onSubmit={handleSubmit(onSubmit)}
        isNewDesign
        title={
          <>
            {t('How can we help?')}
          </>
        }
      >
        <div className="help-view__admin-faq" data-testid="helppage-faq">
          <div className="faq-text">
            {t('Please check out our ')}
            <span>
              <a
                href="https://25350452.fs1.hubspotusercontent-eu1.net/hubfs/25350452/Kerb%20Collateral/FAQ%20and%20User%20Help%20Guides/FAQs%20Dark%20Version.pdf"
                target="_blank" rel="noopener noreferrer">Customer FAQs</a>
            </span>
            {t(', as you may be able to resolve your issue before we can respond.')}
          </div>
        </div>


        <TextField
          {...register('helpText', helpTextFieldValidation)}
          autoFocus
          id="helpText"
          label={t('How can we help?')}
          type="text"
          variant="outlined"
          multiline
          rows={8}
        />
        <FormErrorMessage errors={errors.helpText!} />

        <KdButton
          type="submit"
          disabled={!isValid || isSubmitting}
          className="my-[15px]"
          apiCalling={isSubmitting}
          testId="submit-button"
          fullOnMobile
        >
          {t('Submit')}
        </KdButton>

        <div className="help-view__support-email --italic text-white" data-testid="support-email-text">
          {t('Alternatively, email us at')}{' '}
          <a className="text-white"
             href={`mailto:${schemaInfo.supportEmail}`}
             data-testid="support-email-link"
          >
            <span data-testid="support-email">{schemaInfo.supportEmail}</span>
          </a>
          <p className="help-view__open-hours"
             data-testid="support-openhours-text">{t('Support is available Monday - Friday, 09:00 - 17:00 BST')}</p>
        </div>

      </FormContainer>
    </div>
  );
};
export default HelpPage;
import cn from 'classnames';
import React from 'react';

import { KdSwitchValueType } from '../types/kdSwitchValue';

type Props = {
  /** The additional class name of the switch container */
  className?: string;
  /** The first button text */
  firstText: string;
  /** The onClick handler, takes the selected button index */
  onClick: (value: KdSwitchValueType) => void;
  /** The second button text */
  secondText: string;
  /** The selected index */
  value: KdSwitchValueType;
};

const textClasses = 'relative inline z-10 text-[16px] md:text-[20px]';
const buttonClasses =
  'w-[50%] text-center relative h-full flex items-center justify-center';

const KdSwitch: React.FC<Props> = ({
  className,
  firstText,
  onClick,
  secondText,
  value,
}) => {
  const containerClasses = cn(
    'relative flex items-center bg-accentSecondaryDark rounded-base h-mobileCotrol sm:h-control text-contentColor cursor-pointer shadow-[inset_-3_-3_6px_#00000029] w-full sm:max-w-[470px]',
    className
  );
  const highlightClasses = cn(
    'absolute w-[50%] h-full bg-accentTertiary rounded-base transition-all duration-300 shadow-md',
    {
      'left-[0]': value === 0,
      'left-[50%]': value === 1,
    }
  );

  return (
    <div
      className={containerClasses}
      data-testid="kd-switch"
    >
      <div
        className={buttonClasses}
        onClick={() => onClick(0)}
        role="button"
      >
        <span className={textClasses}>{firstText}</span>
      </div>
      <div
        className={buttonClasses}
        onClick={() => onClick(1)}
        role="button"
      >
        <span className={textClasses}>{secondText}</span>
      </div>
      <div
        className={highlightClasses}
        data-testid="switch-highlight"
      />
    </div>
  );
};

export default KdSwitch;

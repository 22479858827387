import { useEffect, useState } from 'react';
import semver from 'semver';

import config from '../../package.json';
import { callCheckMinVersion } from '../api/kerb';
import { VersionResponseType } from '../api/types';
import { useAuth } from '../auth/hooks/useAuth';
import {
  convertToVersionNumber,
  shouldUpgradeVersion,
} from '../functions/utils';
import useHandleToast from '../hooks/useHandleToast';
import VersionOutOfDateNotifier from './Modals/VersionOutOfDateNotifier';

const UpdateChecker = (): JSX.Element => {
  const { isLoggedIn } = useAuth();
  const [minVersion, setMinVersion] = useState(NaN);
  const [currentVersion, setCurrentVersion] = useState(NaN);
  const [ignoreUpdate, setIgnoreUpdate] = useState(false);
  const { handleToast } = useHandleToast();

  const checkVersion = () => {
    callCheckMinVersion()
      .then((response: VersionResponseType) => {
        setMinVersion(
          convertToVersionNumber(response.min_kd_admin_web_version)
        );
        const appVersion = semver.parse(config.version)?.patch;
        setCurrentVersion(appVersion!);
      })
      .catch((err: Error) => {
        handleToast({ content: err?.message });
      });
  };

  useEffect(() => {
    if (isLoggedIn) checkVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <VersionOutOfDateNotifier
      show={
        shouldUpgradeVersion(currentVersion, minVersion) &&
        ignoreUpdate === false
      }
      minVersion={minVersion}
      currentVersion={currentVersion}
      onIgnore={() => setIgnoreUpdate(true)}
    />
  );
};

export default UpdateChecker;

/* eslint-disable camelcase, @typescript-eslint/no-explicit-any */
type awsconfigType = {
  aws_project_region: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: any;
  aws_cloud_logic_custom: Array<any>;
};

export const awsconfig: awsconfigType = {
  aws_project_region: process.env.REACT_APP_REGION || "INVALID_REGION",
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION || "INVALID_COGNITO_REGION",
  aws_user_pools_id: process.env.REACT_APP_USERPOOLID || "INVALID_USERPOOLID",
  aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOLWEBCLIENTID || "INVALID_USERPOOLWEBCLIENTID",
  oauth: {},
  aws_cloud_logic_custom: [],
};

export const awsconfigLA: awsconfigType = {
  aws_project_region: process.env.REACT_APP_REGION || "INVALID_REGION",
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION || "INVALID_COGNITO_REGION",
  aws_user_pools_id: process.env.REACT_APP_USERPOOLID_LA || "INVALID_USERPOOLID",
  aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOLWEBCLIENTID_LA || "INVALID_USERPOOLWEBCLIENTID",
  oauth: {},
  aws_cloud_logic_custom: [],
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import moment, { MomentInput } from 'moment-timezone';

import { regexp } from '../data/regexp';
import { DataDogEnviromentNamesEnum } from '../types/enums';

export const persistStoreToSessionStorage = (store: any) => {
  store.subscribe(() => {
    localStorage.setItem('reduxStore', JSON.stringify(store.getState()));
  });
};

/**
 * @param {String} password - password to run the tests on
 * @returns {Object} returns object with 5 properties
 * @returns {boolean} .passwordLength checks if string length is bigger than 8
 * @returns {boolean} .passwordUppercase checks if string contains at least 1 uppercase letter
 * @returns {boolean} .passwordLowercase checks if string contains at least 1 lowercase letter
 * @returns {boolean} .passwordNumber checks if string contains at least a single digit
 * @returns {boolean} .passwordSymbol checks if string contains at least a single special symbol
 */

const regexpUppercase = /([A-Z])/;
const regexpLowercase = /([a-z])/;
const regexpNumber = /([0-9])/;
const regexpSymbol = /(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])/;

export const getPasswordValidationState = (password: string) => ({
  passwordLength: password.length > 8,
  passwordUppercase: regexpUppercase.test(password),
  passwordLowercase: regexpLowercase.test(password),
  passwordNumber: regexpNumber.test(password),
  passwordSymbol: regexpSymbol.test(password),
});

export const initGoogleAnalytics = () => {
  const scriptAsync = document.createElement('script');
  const script = document.createElement('script');

  scriptAsync.async = true;
  scriptAsync.src = 'https://www.googletagmanager.com/gtag/js?id=G-X9VFNPDQV6';

  script.text = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-X9VFNPDQV6');
  `;

  document.head.appendChild(scriptAsync);
  document.head.appendChild(script);
};

export const initHotJar = () => {
  const script = document.createElement('script');

  script.text = `
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:2326830,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

  document.head.appendChild(script);
};

export const convertTZ = (date: MomentInput, tzString: string) => {
  const utcDate = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
  const convertedDate = moment.tz(utcDate, tzString).toDate();
  return convertedDate;
};

export const matchToObjectTemplate = (
  persistedState: any,
  currentStateTemplate: any
) => {
  // Hard copies the state template - avoids accidental overriding.
  const outputObject = JSON.parse(JSON.stringify(currentStateTemplate));
  const persistedStateKeys = Object.keys(persistedState);

  // Values from persistedState are copied over to output object]
  // This ensures the returned object has all fields (even null) expected.
  // Old stores saved will not have new fields added when updating Redux stores

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(outputObject)) {
    // If the value is an object, the process is repeated so the object shapes within other objects match.
    if (key in persistedState && typeof value === 'object' && value !== null) {
      outputObject[key] = matchToObjectTemplate(
        persistedState[key],
        outputObject[key]
      );
    } else if (key in persistedState) {
      // Non-object values can be copied exactly - strings, numbers and arrays
      outputObject[key] = persistedState[key];
    }
  }

  // This checks the persisted data for data that isn't defined in the template
  // anything that hasn't been copied over gets copied over so no data is lost.
  persistedStateKeys.forEach((persistedStateKey) => {
    if (!(persistedStateKey in currentStateTemplate)) {
      outputObject[persistedStateKey] = persistedState[persistedStateKey];
    }
  });

  return outputObject;
};

// mostly used for converting version numbers from string to number
export const convertToVersionNumber = (value: string): number => {
  try {
    const result = parseInt(value, 10);
    if (isNaN(result)) {
      return NaN;
    }
    return result;
  } catch (e) {
    return NaN;
  }
};

export const shouldUpgradeVersion = (
  currentVersion: number,
  minimumVersion: number
): boolean => {
  if (isNaN(currentVersion) || isNaN(minimumVersion)) {
    return false;
  }
  return currentVersion < minimumVersion;
};

export const capitalizeFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const sortByString = (a: string, b: string) => {
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  }

  if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  }

  return 0;
};

export const inDropdownAbleMode = (
  name: string,
  activePicker: string,
  isMobile: boolean
) => !isMobile || (isMobile && (activePicker === name || activePicker === ''));

export const convertBreakpointStringToNumber = (str: string) => {
  const numOnly = str.replace(/\D/g, '');
  if (numOnly.length <= 0) {
    return 0;
  }
  return parseInt(numOnly);
};

export const getFirstPartOfEmail = (str: string) => {
  if (str.includes('@') && str.charAt(0) !== '@') {
    return str.split('@')[0];
  }
  return str;
};

export const truncateString = (str: string, length: number) =>
  str?.length > length ? `${str?.slice(0, length)}...` : str;

export const handleCognitoNameAssignment = ({
  cognitoUser,
  username,
}: {
  cognitoUser: { attributes: { name?: string; email?: string } };
  username: string;
}) => {
  const fullName = cognitoUser?.attributes?.name;
  const email = cognitoUser?.attributes?.email ?? username;
  const tempName = fullName
    ? fullName
    : `${getFirstPartOfEmail(email)} ${getFirstPartOfEmail(email)}`;
  return tempName;
};

export const convertEnvName = (env: string) => {
  if (env === 'prod') return DataDogEnviromentNamesEnum.PRODUCTION;
  if (env === 'stg') return DataDogEnviromentNamesEnum.STAGING;

  return DataDogEnviromentNamesEnum.DEVELOPMENT;
};

export const removeHtmlTags = (field: string) =>
  field?.replace(regexp.removeHtml, '');

import Joi from 'joi';

import { regexp } from '../../data/regexp';

export const createHelpScheme = Joi.object({
  message: Joi.string().required().label('Message'),
  addressee: Joi.string()
    .required()
    .valid('GRID', 'CTC')
    .label('Support Addressee')
    .messages({
      'any.only': '{{#label}} is invalid',
    }),
  subject: Joi.string()
    .required()
    .pattern(regexp.text)
    .label('Subject')
    .messages({ 'string.pattern.base': '{{#label}} is invalid' }),
});

const useKdPagingArrowPicker = ({
  pageNumber,
  totalPages,
  setPageNumber,
}: {
  pageNumber: number;
  setPageNumber: (arg0: number) => void;
  totalPages?: number;
}) => {
  const handleLeftArrowClick = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (pageNumber !== totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return { handleLeftArrowClick, handleRightArrowClick };
};
export default useKdPagingArrowPicker;

import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { callCreateHelpTicket } from '../../../api/kerb';
import { removeHtmlTags } from '../../../functions/utils';
import useSubmitForm from '../../../hooks/useSubmitForm';

type HelpFormValues = {
  helpText: string;
};

const useHelpForm = () => {
  const [formValidated, setFormValidated] = useState(false);
  const { submitForm, isSubmitting } = useSubmitForm<null>();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control, // Added this line to get control on form submit validation
  } = useForm<HelpFormValues>({
    mode: 'onTouched',
  });

  useEffect(() => {
    const errorsLength = Object.keys(errors).length;
    if (errorsLength === 0) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  }, [errors]);

  const handleSubmitSucess = () => {
    navigate('/help/finished');
  };

  const onSubmit: SubmitHandler<HelpFormValues> = (data) => {
    if (formValidated) {
      submitForm({
        service: callCreateHelpTicket,
        params: {
          message: removeHtmlTags(data?.helpText),
          addressee: 'GRID',
          subject: 'Support Message',
        },
        onSuccess: handleSubmitSucess,
        successMessage: t('Your message successfully sent.'),
      });
    }
  };

  return {
    onSubmit,
    register,
    handleSubmit,
    isSubmitting,
    errors,
    control, // Return control
    formValidated,
    setFormValidated,
    handleSubmitSucess,
  };
};

export default useHelpForm;

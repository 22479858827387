import cn from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
  testId?: string;
  variant?: 'desktop' | 'mobile';
}

const HeaderContainer: React.FC<Props> = ({
  className,
  children,
  testId,
  variant = 'desktop',
}) => {
  const classes = cn(
    'bg-secondary px-[20px] shadow-[0px_3px_6px_#00000029] flex items-center relative z-[2500]',
    variant === 'mobile'
      ? 'h-mobileHeader justify-center'
      : 'h-header justify-between',
    className
  );

  return (
    <header
      className={classes}
      data-testid={testId}
    >
      {children}
    </header>
  );
};

export default HeaderContainer;

import { useNavigate } from 'react-router-dom';

const useNotFoundPage = () => {
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate('/');
  };

  return { handleReturnHome };
};

export default useNotFoundPage;

import InfoIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import cn from 'classnames';
import React from 'react';

import EditIcon from '../../assets/images/EditIcon';
import { KdInputType } from '../../types/kdInput';

const KdInput = React.forwardRef<HTMLDivElement, KdInputType>(
  (
    {
      className,
      disabled,
      error,
      icon = <EditIcon />,
      iconAriaLabel,
      iconPosition = 'outside',
      isReadOnly,
      label,
      onIconClick,
      onIconMouseDown,
      showIcon,
      success,
      testId,
      testErrorId,
      testSuccessId,
      ...props
    },
    ref
  ) => {
    const classes = cn(
      'kd-input',
      {
        'kd-input_readonly': isReadOnly,
        'kd-input_icon': iconPosition === 'outside',
      },
      className
    );

    return (
      <TextField
        {...props}
        className={classes}
        data-testid={testId}
        disabled={disabled || isReadOnly}
        error={Boolean(error)}
        helperText={
          <>
            {error && (
              <>
                <InfoIcon className="fill-white text-[15px] mr-[5px] mt-[-2px]" />
                <span data-testid={testErrorId}>{error}</span>
              </>
            )}
            {success && (
              <span
                className="validation --valid mt-[8px]"
                data-testid={testSuccessId}
              >
                {success}
              </span>
            )}
          </>
        }
        label={label}
        ref={ref}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: showIcon && !isReadOnly && (
            <InputAdornment position="end">
              <IconButton
                aria-label={iconAriaLabel}
                onClick={onIconClick}
                onMouseDown={onIconMouseDown}
                edge="end"
                tabIndex={1}
              >
                {icon}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default KdInput;

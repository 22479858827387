import { datadogRum } from '@datadog/browser-rum';
import { Suspense, lazy, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { AppContextType, AppStateProvider } from './hooks/useAppState';
import AppRouter from './router/AppRouter';
import KdModalController from './shared/Modals/hooks/KdModalController';
import { RootState } from './store';
import { useAppDispatch } from './useAppDispatch';

const KdToastController = lazy(
  () => import('./shared/Toast/KdToastController')
);

const App = () => {
  const dispatch = useAppDispatch();
  const [appState] = useState<Partial<AppContextType>>({
    activePicker: '',
  });

  const featureFlagStore = useSelector(
    (state: RootState) => state.featureFlagStore
  );

  const reduxStore = localStorage.getItem('reduxStore');

  const resetInnerWidth = () => {
    document.documentElement.style.setProperty(
      '--windowInnerHeight',
      `${window.innerHeight}px`
    );
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', resetInnerWidth);
    resetInnerWidth();
    return () => {
      window.removeEventListener('resize', resetInnerWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reduxStore === null || JSON.parse(reduxStore) === null) {
      dispatch({
        type: 'RESET_STORE',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxStore]);

  useEffect(() => {
    Object.entries(featureFlagStore).find(([key, value]) =>
      datadogRum.addFeatureFlagEvaluation(key, value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<div>...</div>}>
      <ErrorBoundary>
        <AppStateProvider value={appState}>
          <KdToastController />
          <KdModalController />
          <AppRouter />
        </AppStateProvider>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;

import { SchemaColours, SchemaElements } from '../../types/schema';

const schemaColours: SchemaColours = require(`../../theme/${process.env.REACT_APP_THEME}/schemaColours.json`);
const schemaElements: SchemaElements = require(`../../theme/${process.env.REACT_APP_THEME}/schemaElements.json`);

const tabsStyles = {
  border: `${schemaElements.controlBorderWidth} solid ${schemaColours.borderColor}`,
  borderRadius: schemaElements.borderRadiusBase,
  minHeight: '60px',
  padding: '10px 30px',
  marginBottom: '30px',
  alignItems: 'center',
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-between',
  },
  '& .MuiButtonBase-root': {
    borderRadius: schemaElements.borderRadiusBase,
    border: '0 none',
    color: schemaColours.controlColour,
    opacity: '1',
    fontFamily: 'Open Sans, sans-serif',
    textTransform: 'none',
    fontSize: '20px',
    minHeight: '36px',
    height: '36px',
    padding: '5px 10px',
    ['@media (min-width: 1025px)']: {
      minWidth: '220px',
    },
  },
  '& .Mui-selected': {
    backgroundColor: schemaColours.accentSecondaryDark,
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
};

export default tabsStyles;

import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import useMenu from './hooks/useMenu';
import { MenuType } from './types/menuTypes';
import MenuDesktop from './ui/MenuDesktop';
import MenuMobile from './ui/MenuMobile';

interface Props {
  type: MenuType;
}

const KdMenu = ({ type }: Props) => {
  const { menuItems, handleClick } = useMenu();

  const viewStore = useSelector((state: RootState) => state.viewStore);
  const { selectedHighlight } = viewStore;

  return (
    <>
      {type === 'mobile' ? (
        <MenuMobile
          menuItems={menuItems}
          onClick={handleClick}
          selectedHighlight={selectedHighlight}
        />
      ) : (
        <MenuDesktop
          menuItems={menuItems}
          onClick={handleClick}
          selectedHighlight={selectedHighlight}
        />
      )}
    </>
  );
};

export default KdMenu;

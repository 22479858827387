import React from 'react';
import { ReactNode, createContext, useMemo, useState } from 'react';

export type AppContextType = {
  setActivePicker: (pickerId: string | '') => void;
  activePicker: string | '';
  selectedBayId?: string;
  setSelectedBayId: (bayId: string | undefined) => void;
};
export const AppContext = createContext<Partial<AppContextType>>({});

export const AppStateProvider = ({
  value,
  children,
}: {
  value: Partial<AppContextType>;
  children: ReactNode;
}) => {
  const [state, setState] = useState<Partial<AppContextType>>(value);
  const setActivePicker = (pickerId: string) => {
    setState({ ...state, activePicker: pickerId ?? '' });
  };

  const setSelectedBayId = (bayId: string | undefined) => {
    setState({ ...state, selectedBayId: bayId });
  };

  const returnValue = useMemo(
    () => ({ ...state, setActivePicker, setSelectedBayId }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return (
    <AppContext.Provider value={returnValue}>{children}</AppContext.Provider>
  );
};

export const useAppState = () => React.useContext(AppContext);

import Joi from 'joi';

import { regexp } from '../../data/regexp';

export const getBookingsScheme = Joi.object({
  date: Joi.string().required().label('Bookings Date'),
  pageNumber: Joi.number().integer().label('Page Number'),
});

export const createBookingScheme = Joi.object({
  start: Joi.string()
    .required()
    .pattern(regexp.dateTime)
    .label('Start Date')
    .messages({
      'string.pattern.base': '{{#label}} is invalid',
    }),
  end: Joi.string()
    .required()
    .pattern(regexp.dateTime)
    .label('End Date')
    .messages({
      'string.pattern.base': '{{#label}} is invalid',
    }),
  vehicleID: Joi.string()
    .required()
    .pattern(regexp.id)
    .label('Vehicle ID')
    .messages({
      'string.pattern.base': '{{#label}} is invalid',
    }),
  bayID: Joi.string().required().pattern(regexp.id).label('Bay ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
  driverID: Joi.string().pattern(regexp.id).label('Driver ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
});

export const updateBookingScheme = Joi.object({
  id: Joi.string().required().pattern(regexp.id).label('Booking ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
  vehicleID: Joi.string().pattern(regexp.id).label('Vehicle ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
  start: Joi.string().pattern(regexp.dateTime).label('Start Date').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
  end: Joi.string().pattern(regexp.dateTime).label('End Date').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
});

export const deleteBookingScheme = Joi.object({
  id: Joi.string().required().pattern(regexp.id).label('Booking ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
});

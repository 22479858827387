import { VehicleTypesEnum } from '../../types/enums';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  rejectRequest,
} from '../fetch';
import {
  CreateVehicleRequestType,
  GetVehiclesRequestType,
  UpdateVehicleRequestType,
} from '../types';
import {
  createVehicleScheme,
  getVehiclesScheme,
  updateVehicleScheme,
  vehicleScheme,
} from '../validation';

export const callGetPagedVehicles = ({
  numberOfVehicles,
  pageNumber,
  vehicleType,
  fuelType,
  searchValue,
}: GetVehiclesRequestType) => {
  const { error } = getVehiclesScheme.validate({
    numberOfVehicles,
    pageNumber,
    vehicleType,
    fuelType,
    searchValue,
  });

  if (error) {
    return rejectRequest(error?.message);
  }

  const vehicle = vehicleType || VehicleTypesEnum.ALL;
  const searchParam = searchValue
    ? `&vrm=${encodeURIComponent(searchValue)}&is_partial_search=true`
    : '';
  const fuelParam = fuelType ? `&fuel_type=${fuelType}` : '';

  return getRequest(
    `/paging/vehicles?limit=${numberOfVehicles}&page_number=${pageNumber}&vehicle_type=${vehicle}${searchParam}${fuelParam}`
  );
};

export const callGetAllVehicles = () => getRequest('/vehicles');

export const callGetVehicleByID = ({ id }: { id: string }) => {
  const { error } = vehicleScheme.validate({ id });

  if (error) {
    return rejectRequest(error?.message);
  }

  return getRequest(`/vehicles/${id}`);
};

export const callCreateVehicle = ({
  vrm,
  vehicleType,
}: CreateVehicleRequestType) => {
  const { error } = createVehicleScheme.validate({ vrm, vehicleType });

  if (error) {
    return rejectRequest(error?.message);
  }

  return postRequest('/vehicles', { vrm, vehicle_type: vehicleType });
};

export const callPatchVehicle = ({ id, vrm }: UpdateVehicleRequestType) => {
  const { error } = updateVehicleScheme.validate({ id, vrm });

  if (error) {
    return rejectRequest(error?.message);
  }

  return patchRequest(`/vehicles/${id}`, { vrm });
};

export const callDeleteVehicle = ({ id }: { id: string }) => {
  const { error } = vehicleScheme.validate({ id });

  if (error) {
    return rejectRequest(error?.message);
  }

  return deleteRequest(`/vehicles/${id}`);
};

export const callGetVehicleBookings = ({ id }: { id: string }) => {
  const { error } = vehicleScheme.validate({ id });

  if (error) {
    return rejectRequest(error?.message);
  }

  return getRequest(`/vehicles/${id}/bookings`);
};

import cn from 'classnames';

import useDropdownItem from '../hooks/useDropdownItem';
import { TopbarButtonType } from './HeaderDesktop';

type dropdownItemType = {
  button: TopbarButtonType;
  isSelected: boolean;
  onHandleClick: () => void;
};
const DropdownItem = ({
  button,
  isSelected,
  onHandleClick,
}: dropdownItemType) => {
  const { handleClick, size } = useDropdownItem({ button, onHandleClick });

  const classnames = cn(
    'w-full flex items-center text-[20px] px-[5px] py-[7px] hover:bg-buttonSecondaryHover focus:bg-buttonSecondaryHover rounded-lg ',
    {
      'bg-buttonSecondaryHover': isSelected,
    }
  );

  const reactSVGclassnames = cn(
    size === 'small' ? 'w-[20px] rotate-180 ml-[3px]' : 'w-full'
  );

  return (
    <div
      data-testid={button?.title}
      role="button"
      tabIndex={0}
      className={classnames}
      onClick={handleClick}
      onMouseEnter={onHandleClick}
      onMouseLeave={() => {}}
      key={button.title}
    >
      <div className="w-[23px] mr-[12px]">
        <button.icon className={reactSVGclassnames} />
      </div>
      <span>{button.title}</span>
    </div>
  );
};
export default DropdownItem;

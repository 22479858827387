import { useEffect, useState } from 'react';
import Slider from 'react-slick';

const useKdCarousel = ({ activeSlideIndex }: { activeSlideIndex?: number }) => {
  const [slider, setSlider] = useState<Slider | null>(null);

  useEffect(() => {
    if (activeSlideIndex) {
      slider?.slickGoTo(activeSlideIndex, false);
    }
  }, [activeSlideIndex, slider]);

  return { slider, setSlider };
};
export default useKdCarousel;

const useKdPaging = ({
  totalPages = 0,
  setPageNumber,
}: {
  totalPages?: number;
  setPageNumber: (arg0: number) => void;
}) => {
  const handleChange = (value: string) => {
    setPageNumber(isNaN(parseInt(value)) ? 0 : parseInt(value));
  };

  const options = [...Array(totalPages).keys()].map((number: number) => ({
    key: String(number + 1),
    label: String(number + 1),
  }));
  return { handleChange, options };
};
export default useKdPaging;

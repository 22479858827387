import { useNavigate } from 'react-router-dom';

const useHelpFinished = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/dashboard');
  };

  return { onClick };
};
export default useHelpFinished;

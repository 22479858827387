import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import cn from 'classnames';
import React from 'react';

import { ReactComponent as FilterIcon } from '../../assets/images/Filter_Icon.svg';
import { KdSearchType } from '../../types/kdSearch';

const KdSearch: React.FC<KdSearchType> = ({
  className,
  classNameField,
  onRightIconClick,
  onRightIconMouseDown,
  renderMenu,
  rightIcon = <FilterIcon className="text-borderColor" />,
  rightIconAriaLabel = 'Filter icon',
  showRightIcon,
  testId,
  ...props
}) => {
  const classes = cn(
    'relative sm:min-w-[470px] xl:min-w-[380px] 2xl:min-w-[470px]',
    className
  );
  const classesField = cn('kd-input kd-input_search !mb-0', classNameField);

  return (
    <div className={classes}>
      <TextField
        {...props}
        data-testid={testId}
        className={classesField}
        hiddenLabel
        type="text"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                aria-label="Search icon"
                className="text-borderColor"
              />
            </InputAdornment>
          ),
          endAdornment: showRightIcon && (
            <InputAdornment position="end">
              <IconButton
                aria-label={rightIconAriaLabel}
                onClick={onRightIconClick}
                onMouseDown={onRightIconMouseDown}
              >
                {rightIcon}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {renderMenu}
    </div>
  );
};
export default KdSearch;

import { format } from 'date-fns';
import { t } from 'i18next';
import { DateRange } from 'react-day-picker';

const useKdDayPickerCaption = ({
  range,
  selectedDate,
  selectedText,
}: {
  range: DateRange | undefined;
  selectedDate: Date | undefined;
  selectedText: string;
}) => {
  const formatDate = (date: Date | undefined) => {
    if (!date) {
      return '';
    }
    return format(date, 'dd/MM/yyyy 00:00:00').replaceAll('00:00:00', '');
  };

  const startDate = range?.from ? formatDate(range.from) : '';
  const endDate = range?.to ? formatDate(range.to) : '';
  const formattedSelectedDate = formatDate(selectedDate);

  const selectedContent = () => {
    if (startDate) {
      return `${startDate}${endDate ? `- ${endDate}` : ''}`;
    }

    if (selectedDate) {
      return `${selectedText} ${formattedSelectedDate}`;
    }

    return t('Choose a Date');
  };
  return {
    startDate,
    endDate,
    selectedContent,
    formatDate,
  };
};
export default useKdDayPickerCaption;

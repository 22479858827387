import { useState } from 'react';

const useMenuMoreButton = ({
  onClick = () => {},
}: {
  onClick?: () => void;
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
    onClick();
  };

  const handleHide = () => {
    setShow(false);
  };

  return { handleHide, handleShow, show, setShow };
};
export default useMenuMoreButton;

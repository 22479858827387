const MenuArrowActive = ({ className }: { className?: string }) => (
  <svg
    id="dropdown"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      id="Shape"
      d="M1.762,0,0,1.762,5.725,7.5,0,13.238,1.762,15l7.5-7.5Z"
      transform="translate(7.59 4)"
    />
    <path
      id="Shape-2"
      data-name="Shape"
      d="M0,0H24V24H0Z"
      fill="none"
    />
  </svg>
);

export default MenuArrowActive;
MenuArrowActive.defaultProps = {
  className: '',
};

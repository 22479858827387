import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import cn from 'classnames';
import React, { Fragment, MouseEventHandler } from 'react';

type BreadcrumbsLinkType = {
  text: string;
  url?: string;
};

type Props = {
  /** The additional class name of the breadcrumbs, used for customising the existing style and tweaking the look and feel */
  className?: string;
  /** The last item text */
  lastItemText: string;
  /** The array of provided links */
  links: BreadcrumbsLinkType[];
  /** The size of the breadcrumbs, it can be 'default', 'small', 'tiny' */
  size?: 'default' | 'small' | 'tiny';
  /** The onClick handler */
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
};

const itemCommonClasses = 'font-semibold text-contentColor whitespace-nowrap';

const KdBreadcrumbs: React.FC<Props> = ({
  className,
  lastItemText,
  links,
  size = 'default',
  onClick,
}) => {
  const classes = cn('flex items-center h-[36px]', className);

  const itemSizeClasses = cn({
    'text-[28px]': size === 'default',
    'text-[20px] font-normal': size === 'small',
    'text-[16px] font-normal': size === 'tiny',
  });

  const chevronClassnames = cn(
    'text-borderColor',
    size === 'tiny' ? ' w-[25px] h-[25px]' : ' w-[36px] h-[36px]'
  );

  const itemClasses = `${itemSizeClasses} ${itemCommonClasses} hover:text-accentPrimary focus:text-accentPrimary`;

  return (
    <div className={classes}>
      {links?.map((link) => (
        <Fragment key={`${link.text}-${link.url}`}>
          {link?.url ? (
            <a
              className={itemClasses}
              href={`#${link?.url}`}
              onClick={onClick}
            >
              {link.text}
            </a>
          ) : (
            <button
              className={itemClasses}
              onClick={onClick}
              type="button"
            >
              {link.text}
            </button>
          )}
          {lastItemText && <ChevronRightIcon className={chevronClassnames} />}
        </Fragment>
      ))}
      <span
        className={`${itemSizeClasses} ${itemCommonClasses} truncate`}
        title={lastItemText}
      >
        {lastItemText}
      </span>
    </div>
  );
};
export default KdBreadcrumbs;

import { ExpandMore } from '@mui/icons-material';
import cn from 'classnames';
import React from 'react';
import { DateRange } from 'react-day-picker';

import { ReactComponent as Callendar } from '../../../assets/images/bookings.svg';
import useKdDayPickerCaption from '../hooks/useKdDayPickerCaption';

interface Props {
  className?: string;
  isDropdownOpen?: boolean;
  isMobile?: boolean;
  noArrow?: boolean;
  onClick?: () => void;
  range?: DateRange | undefined;
  selectedDate?: Date;
  selectedText?: string;
  testId?: string;
  changingAllowed?: boolean;
}

const KdDayPickerCaption: React.FC<Props> = ({
  className,
  isDropdownOpen,
  isMobile,
  noArrow = false,
  onClick,
  range,
  selectedDate,
  selectedText = 'View bookings for',
  testId,
  changingAllowed = true,
}) => {
  const { startDate, selectedContent } = useKdDayPickerCaption({
    range,
    selectedDate,
    selectedText,
  });

  const classes = cn(
    'h-[50px] sm:h-[59px] flex items-center bg-pickerBgColor cursor-pointer rounded-base px-[10px]',
    {
      '!bg-white text-accentSecondary': !startDate && !selectedDate,
      'whitespace-nowrap': selectedDate,
    },
    className,
    changingAllowed
      ? 'pointer-events-auto cursor-pointer'
      : 'pointer-events-none cursor-default'
  );

  const dropdownArrowClasses = cn(
    'w-[30px] h-[30px] mr-[-5px] sm:mr-0',
    isDropdownOpen ? 'rotate-180' : 'rotate-[270deg]'
  );

  const contentClasses = cn(
    'text-textXs sm:text-textBase w-4/5',
    isMobile && 'pl-[23px]'
  );

  const iconClasses = cn('flex justify-center w-1/5 mr-[10px]');

  return (
    <div
      className={classes}
      data-testid={testId}
      onClick={onClick}
      role="button"
    >
      <div className={iconClasses}>
        <Callendar className="h-[25px] w-[25px] sm:h-[30px] sm:w-[30px]" />
      </div>
      <span
        className={contentClasses}
        data-testid="selected-dates-caption"
      >
        {selectedContent()}
      </span>
      {onClick && (
        <div className="flex justify-end w-1/5 ">
          {!noArrow && (
            <ExpandMore
              className={dropdownArrowClasses}
              fontSize="large"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default KdDayPickerCaption;

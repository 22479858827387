import Joi from 'joi';

import { regexp } from '../../data/regexp';

export const analyticsScheme = Joi.object({
  startDate: Joi.string()
    .required()
    .pattern(regexp.dateTime)
    .label('Start Date')
    .messages({ 'string.pattern.base': '{{#label}} is invalid' }),
  endDate: Joi.string()
    .required()
    .pattern(regexp.dateTime)
    .label('End date')
    .messages({ 'string.pattern.base': '{{#label}} is invalid' }),
});

import { useAuth } from '../../../auth/hooks/useAuth';

const useLogo = () => {
  const getUserTypeUrl = (userType: string) => {
    if (userType === 'CF') {
      return '/#/login';
    }

    return '/#/login-la';
  };

  const { userType, isLoggedIn } = useAuth();

  const logoUrl = isLoggedIn ? '/#/dashboard' : getUserTypeUrl(userType);

  return { logoUrl, getUserTypeUrl };
};
export default useLogo;

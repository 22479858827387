import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
  index: number;
  value: number;
}

const KdTabContent: React.FC<Props> = ({
  children,
  className,
  index,
  value,
  ...props
}) => (
  <div
    aria-labelledby={`tab-${index}`}
    className={className}
    hidden={value !== index}
    id={`tab-${index}`}
    role="tabpanel"
    {...props}
  >
    {children}
  </div>
);

export default KdTabContent;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAuth } from '../../../auth/hooks/useAuth';
import getLandingPageButtons from '../../../data/landingPageButtons';
import useRedirect from '../../../hooks/useRedirect';
import i18n, { t } from '../../../i18n';
import { RootState } from '../../../store';
import { UserType } from '../../../types/common';
import { LandingButtonType } from '../../../types/landingButton';
import { useAppDispatch } from '../../../useAppDispatch';

export type LandingViewProps = {
  buttons: LandingButtonType[];
  setUserType?: (userType: UserType) => void;
  redirectTo: (path: string) => void;
};

const useLandingView = (): LandingViewProps => {
  const { setUserType } = useAuth();
  const { redirectTo } = useRedirect();
  const dispatch = useAppDispatch();
  const featureFlagStore = useSelector(
    (state: RootState) => state.featureFlagStore
  );
  const { laUserFlow } = featureFlagStore;
  const [buttons, setButtons] = useState<LandingButtonType[]>(
    getLandingPageButtons(t, laUserFlow)
  );

  useEffect(() => {
    // reset relevant store values when user lands back on Dashboard
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
    dispatch({
      type: 'SET_CURRENT_SUBROUTES_DATA_ARRAY',
      payload: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    i18n.loadNamespaces(['translation']).then(() => {
      setButtons(getLandingPageButtons(t, laUserFlow));
    });

    const newButtons: LandingButtonType[] = buttons.map(
      (button: LandingButtonType) => {
        if (button.userType === 'LA') {
          return {
            ...button,
            visible: laUserFlow,
          };
        }
        return button;
      }
    );
    setButtons(newButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laUserFlow]);

  return {
    buttons,
    setUserType,
    redirectTo,
  };
};

export default useLandingView;

import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  rejectRequest,
} from '../fetch';
import {
  BookingsRequestType,
  CreateBookingRequestType,
  UpdateBookingRequestType,
} from '../types';
import {
  createBookingScheme,
  deleteBookingScheme,
  getBookingsScheme,
  updateBookingScheme,
} from '../validation';

export const callGetPagedBookings = ({
  date,
  pageNumber,
}: BookingsRequestType) => {
  const { error } = getBookingsScheme.validate({ date, pageNumber });

  if (error) {
    return rejectRequest(error?.message);
  }

  return getRequest(
    `/paging/bookings?date=${date}&limit=300&page_number=${pageNumber}`
  );
};

export const callCreateBooking = ({
  start,
  end,
  vehicleID,
  bayID,
  driverID,
}: CreateBookingRequestType) => {
  const { error } = createBookingScheme.validate({
    start,
    end,
    vehicleID,
    bayID,
    driverID,
  });

  if (error) {
    return rejectRequest(error?.message);
  }

  return postRequest(`/bookings`, {
    start,
    end,
    vehicle_id: vehicleID,
    bay_id: bayID,
    driver_id: driverID,
  });
};

export const callPatchBooking = ({
  id,
  vehicleID,
  start,
  end,
}: UpdateBookingRequestType) => {
  const { error } = updateBookingScheme.validate({
    id,
    start,
    end,
    vehicleID,
  });

  if (error) {
    return rejectRequest(error?.message);
  }

  return patchRequest(`/bookings/${id}`, {
    vehicle_id: vehicleID,
    start,
    end,
  });
};

export const callDeleteBooking = ({ id }: { id: string }) => {
  const { error } = deleteBookingScheme.validate({ id });

  if (error) {
    return rejectRequest(error?.message);
  }

  return deleteRequest(`/bookings/${id}`);
};

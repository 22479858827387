import { t } from 'i18next';
import { useState } from 'react';

import useHandleToast from './useHandleToast';

export type SubmitFormType<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  service: (params: any) => Promise<T>;
  params: { [key: string]: unknown };
  onSuccess: (result: T) => void;
  onFailure?: (error: Error) => void;
  errorMessage?: string;
  successMessage?: string;
  isShowToast?: boolean;
  noTopBar?: boolean;
};

export type ValidationErrorsTypes = {
  errorPhone?: string;
  errorEmail?: string;
  errorUserExist?: string;
};

type Props<T> = {
  validationErrors?: ValidationErrorsTypes;
  clearValidationErrors?: VoidFunction;
  isSubmitting: boolean;
  submitForm: (data: SubmitFormType<T>) => void;
};

const isIncludeText = (error: Error, text: string) =>
  error?.message?.toLowerCase().includes(text?.toLowerCase());

const useSubmitForm = <T>(): Props<T> => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorsTypes | undefined
  >();
  const { handleToast } = useHandleToast();

  const clearValidationErrors = () => {
    setValidationErrors(undefined);
  };

  const submitForm = ({
    service,
    params = {},
    onSuccess,
    onFailure,
    errorMessage,
    successMessage,
    isShowToast = true,
    noTopBar,
  }: SubmitFormType<T>) => {
    setIsSubmitting(true);

    service(params)
      .then((result: T) => {
        if (onSuccess) onSuccess(result);

        if (isShowToast) {
          handleToast({
            variant: 'success',
            content: successMessage || t('Your details have been updated'),
            noTopBar,
          });
        }
      })
      .catch((error: Error) => {
        if (onFailure) onFailure(error);

        if (isIncludeText(error, 'Phone number')) {
          setValidationErrors({
            ...validationErrors,
            errorPhone: error?.message,
          });
          return;
        }

        if (isIncludeText(error, 'Email')) {
          setValidationErrors({
            ...validationErrors,
            errorEmail: error?.message,
          });
          return;
        }

        if (isIncludeText(error, 'User already exists')) {
          setValidationErrors({
            ...validationErrors,
            errorUserExist: error?.message,
          });
          return;
        }

        if (isShowToast) {
          handleToast({
            content: errorMessage || error?.message,
            noTopBar,
          });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return { validationErrors, clearValidationErrors, isSubmitting, submitForm };
};

export default useSubmitForm;

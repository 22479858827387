import { lazy } from 'react';
import {
  Route,
  createHashRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import Developers from '../pages/DeveloperTools/DeveloperToolsView';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import Help from '../pages/Help/HelpPage';
import HelpFinished from '../pages/Help/ui/HelpFinished';
import Home from '../pages/Home/HomePage';
import Landing from '../pages/Landing/ui/LandingView';
import NotFound from '../pages/NotFound/NotFoundPage';
import Localised from '../shared/LocalisedView';
import PrivateRoute from './PrivateRoute';
import { RouterErrorBoundary } from './RouterErrorBoundary';
import RouterProvider from './RouterProvider';

const Login = lazy(() => import('../pages/Login/LoginPage'));
const LoginLa = lazy(() => import('../pages/LoginLa/LoginLaPage'));
const SignUp = lazy(() => import('../pages/Register/SignUpPage'));
const ConfirmationCode = lazy(
  () => import('../pages/ConfirmationCode/ConfirmationCodePage')
);
const ResetPasswordLayout = lazy(
  () => import('../pages/ResetPassword/ResetPasswordPage')
);
const ResetPassword = lazy(
  () => import('../pages/ResetPassword/ui/ResetPasswordForm')
);
const UpdatePassword = lazy(
  () => import('../pages/ResetPassword/ui/UpdatePasswordForm')
);
const NewPassword = lazy(() => import('../pages/NewPassword/NewPasswordPage'));

const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const About = lazy(() => import('../pages/About/AboutPage'));

const AddOrganisation = lazy(
  () => import('../pages/AddOrganisation/AddOrganisationPage')
);
const EditOrganisation = lazy(
  () => import('../pages/EditOrganisation/EditOrganisationPage')
);

const Bookings = lazy(() => import('../pages/Bookings/BookingsPage'));
const CreateBookings = lazy(
  () => import('../pages/CreateBookings/CreateBookingsPage')
);

const AnalyticsLa = lazy(() => import('../pages/Analytics/LA/AnalyticsPage'));
const AnalyticsCf = lazy(() => import('../pages/Analytics/CF/AnalyticsPage'));
const ExportProcess = lazy(
  () => import('../pages/Analytics/ui/ExportProcessPage')
);

const ManageUsers = lazy(() => import('../pages/ManageUsers/ManageUsersView'));
const EditUsers = lazy(() => import('../pages/ManageUsers/EditUsers'));
const CreateUsers = lazy(
  () => import('../pages/ManageUsers/ui/createUsers/CreateUsers')
);

const ManageVehicles = lazy(
  () => import('../pages/ManageVehicles/ManageVehiclesPage')
);
const ManageVehicle = lazy(
  () => import('../pages/ManageVehicles/ui/ManageVehicle')
);
const AddVehicles = lazy(() => import('../pages/AddVehicles/AddVehiclesPage'));

const BulkUploadUsersProgress = lazy(
  () => import('../pages/ManageUsers/ui/createUsers/BulkUploadUsersProgress')
);
const BulkUploadUsersErrors = lazy(
  () => import('../pages/ManageUsers/ui/createUsers/BulkUploadUsersErrors')
);
const BulkUploadVehiclesProgress = lazy(
  () => import('../pages/AddVehicles/ui/BulkUploadProgress')
);
const BulkUploadVehiclesErrors = lazy(
  () => import('../pages/AddVehicles/ui/BulkUploadErrors')
);

const currentEnvironment = process.env.REACT_APP_ENVIRONMENT;
const isNotProduction = currentEnvironment !== 'prod';

const routes = createHashRouter(
  createRoutesFromElements(
    <Route
      element={<RouterProvider />}
      errorElement={<RouterErrorBoundary />}
    >
      <Route
        index
        element={<Home />}
      />
      <Route
        path="landing"
        element={<Landing />}
      />
      <Route
        path="login"
        element={<Login />}
      />
      <Route
        path="login-la"
        element={<LoginLa />}
      />
      <Route
        path="register"
        element={<SignUp />}
      />
      <Route
        path="confirm"
        element={<ConfirmationCode />}
      />
      <Route
        path="resetpassword"
        element={<ResetPasswordLayout />}
      >
        <Route
          index
          element={<ResetPassword />}
        />
        <Route
          path="update"
          element={<UpdatePassword />}
        />
      </Route>
      <Route
        path="newpassword"
        element={<NewPassword />}
      />

      {/* Private Routes */}

      <Route element={<PrivateRoute />}>
        <Route
          path="dashboard"
          element={<Dashboard />}
        />
        <Route
          path="about"
          element={<About />}
        />
        <Route
          path="bookings"
          element={<Bookings />}
        />
        <Route
          path="createbooking"
          element={<CreateBookings />}
        />
        <Route
          path="editbooking"
          element={<CreateBookings />}
        />
        <Route
          path="editorganisation"
          element={<EditOrganisation />}
        />

        <Route path="managevehicles">
          <Route
            index
            element={<ManageVehicles />}
          />
          <Route
            path=":id"
            element={<ManageVehicle />}
          />
        </Route>

        <Route path="addvehicle">
          <Route
            index
            element={<AddVehicles />}
          />
          <Route
            path="progress"
            element={<BulkUploadVehiclesProgress />}
          />
          <Route
            path="errors"
            element={<BulkUploadVehiclesErrors />}
          />
        </Route>

        <Route path="manageusers">
          <Route
            index
            element={<ManageUsers />}
          />
          <Route
            path=":id"
            element={<EditUsers />}
          />
        </Route>

        <Route path="adduser">
          <Route
            index
            element={<CreateUsers />}
          />
          <Route
            path="progress"
            element={<BulkUploadUsersProgress />}
          />
          <Route
            path="errors"
            element={<BulkUploadUsersErrors />}
          />
        </Route>

        <Route path="analytics-cf">
          <Route
            index
            element={<AnalyticsCf />}
          />
          <Route
            path="process"
            element={<ExportProcess />}
          />
        </Route>

        <Route path="analytics-la">
          <Route
            index
            element={<AnalyticsLa />}
          />
          <Route
            path="process"
            element={<ExportProcess />}
          />
        </Route>

        <Route path="help">
          <Route
            index
            element={<Help />}
          />
          <Route
            path="finished"
            element={<HelpFinished />}
          />
        </Route>
      </Route>

      <Route element={<PrivateRoute isMenuAndHeader={false} />}>
        <Route
          path="addorganisation"
          element={<AddOrganisation />}
        />
      </Route>

      {isNotProduction && (
        <Route element={<PrivateRoute />}>
          <Route path="developers">
            <Route
              index
              element={<Developers />}
            />
            <Route
              path="successful-payment"
              element={<Developers />}
            />
            <Route
              path="failed-payment"
              element={<Developers />}
            />
            <Route
              path="localised"
              element={<Localised />}
            />
          </Route>
        </Route>
      )}

      <Route
        path="error"
        element={<ErrorPage />}
      />
      <Route
        path="*"
        element={<NotFound />}
      />
    </Route>
  )
);

export default routes;

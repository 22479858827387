import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import KdDropdown from '../../Dropdown/KdDropdown';
import LoadingCircle from '../../LoadingCircle';
import useKdPaging from '../hooks/useKdPaging';
import KdPagingArrowPicker, { PagingProps } from './KdPagingArrowPicker';

type Props<T> = PagingProps & {
  data: T[];
  renderItems: (data: T[]) => React.ReactNode;
  title?: string;
  isLoading?: boolean;
  arrowPagingClassName?: string;
  className?: string;
};

const KdPaging = <T extends unknown>({
  arrowPagingClassName,
  className,
  data,
  isLoading,
  totalPages,
  pageNumber,
  renderItems,
  setPageNumber,
  title,
}: Props<T>) => {
  const { t } = useTranslation();

  const { handleChange, options } = useKdPaging({ totalPages, setPageNumber });

  const classes = cn('flex flex-col w-full', className);

  return (
    <div className={classes}>
      <div className="flex justify-between items-center mb-[30px]">
        <div className="text-[23px] text-contentColor">{title}</div>
        <div className="flex items-center">
          <div className="mr-[10px] text-textBase">{t('Jump to page')}</div>
          <KdDropdown
            value={String(pageNumber)}
            onChange={handleChange}
            options={options}
            buttonClassName="!h-[40px] !px-[15px] min-w-[75px]"
            menuClassName="!px-[15px] max-h-[200px] overflow-y-auto"
            testId="paging-dropdown"
          />
        </div>
      </div>
      {isLoading ? (
        <LoadingCircle className="sm:h-[calc(100vh-480px)]" />
      ) : (
        <>{renderItems(data)}</>
      )}
      <KdPagingArrowPicker
        className={arrowPagingClassName}
        totalPages={totalPages}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </div>
  );
};
export default KdPaging;

import Joi from 'joi';

import { regexp } from '../../data/regexp';

export const getBaysScheme = Joi.object({
  lat: Joi.number().max(90).min(-90).label('Bays Latitude'),
  lng: Joi.number().max(180).min(-180).label('Bays Longitude'),
  radius: Joi.number().integer().min(0).label('Bays Radius'),
  limit: Joi.number().integer().min(5).label('Bays Limit'),
  page: Joi.number().integer().min(1).label('Bays Page Number'),
});

export const getBayScheme = Joi.object({
  id: Joi.string().required().pattern(regexp.id).label('Bay ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
});

export const getBayHoursScheme = Joi.object({
  id: Joi.string().required().pattern(regexp.id).label('Bay ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
  date: Joi.string()
    .required()
    .pattern(regexp.date)
    .label('Bay Hours Date')
    .messages({ 'string.pattern.base': '{{#label}} is invalid' }),
});

export const getBayAvailabilityScheme = Joi.object({
  id: Joi.string().required().pattern(regexp.id).label('Bay ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
  date: Joi.string()
    .required()
    .pattern(regexp.dateTime)
    .label('Bay Availability Date')
    .messages({ 'string.pattern.base': '{{#label}} is invalid' }),
});

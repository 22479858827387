import i18n, { t } from '../../../i18n';
import { ReactComponent as AnalyticsHoverImgGrid } from '../../../theme/grid/images/dashboard/analytics.svg';
import { ReactComponent as BookingsImgGrid } from '../../../theme/grid/images/dashboard/bookings.svg';
import { ReactComponent as EditOrgImgGrid } from '../../../theme/grid/images/dashboard/org.svg';
import { ReactComponent as ManageUsersImgGrid } from '../../../theme/grid/images/dashboard/users.svg';
import { ReactComponent as ManageVehiclesImgGrid } from '../../../theme/grid/images/dashboard/van.svg';
import { MenuItemsType } from '../types/menuTypes';
import { getMenuDashboardIcon, getMenuIcon } from './getMenuIcon';

export const menuCF: MenuItemsType = [
  {
    title: t('Dashboard'),
    description: '',
    img: getMenuIcon('dashboard'),
    linkHref: '/dashboard',
    highlightLocations: ['dashboard'],
    testId: 'home-button',
    hoverImage: undefined,
    userType: 'CF',
    visibleOnDashboard: false,
  },
  {
    title: t('Bookings'),
    description: t('Create, remove and amend your bookings'),
    img: getMenuIcon('bookings'),
    imgDashboard: getMenuDashboardIcon('bookings'),
    linkHref: '/bookings',
    highlightLocations: ['bookings', 'createbooking', 'editbooking'],
    testId: 'bookings-button',
    hoverImage: BookingsImgGrid,
    userType: 'CF',
  },
  {
    title: t('Manage Fleet'),
    description: t('Make changes to your fleet'),
    img: getMenuIcon('vehicles'),
    imgDashboard: getMenuDashboardIcon('vehicles'),
    linkHref: '/managevehicles',
    highlightLocations: ['managevehicles', 'addvehicle', 'vehicleupload'],
    testId: 'manage-vehicles-button',
    hoverImage: ManageVehiclesImgGrid,
    userType: 'CF',
  },
  {
    title: t('Manage Users'),
    description: t('Manage the users in your organisation and add new users'),
    img: getMenuIcon('users'),
    imgDashboard: getMenuDashboardIcon('users'),
    linkHref: '/manageusers',
    highlightLocations: ['manageusers', 'adduser'],
    testId: 'manage-users-button',
    hoverImage: ManageUsersImgGrid,
    userType: 'CF',
  },
  {
    title: t('Analytics'),
    description: t('View the analytics for the loading bays in your area'),
    img: getMenuIcon('analytics'),
    imgDashboard: getMenuDashboardIcon('analytics'),
    linkHref: '/analytics-cf',
    highlightLocations: ['analytics-cf'],
    testId: 'analytics-button',
    hoverImage: AnalyticsHoverImgGrid,
    userType: 'CF',
  },
  {
    title: t('Edit Organisation'),
    description: '',
    img: getMenuIcon('organisation'),
    linkHref: '/editorganisation',
    highlightLocations: ['editorganisation'],
    testId: 'edit-organisation-button',
    hoverImage: EditOrgImgGrid,
    userType: 'CF',
    visibleOnDashboard: false,
  },
  {
    title: t('Help'),
    description: t('Request for help'),
    img: getMenuIcon('help'),
    linkHref: '/help',
    highlightLocations: ['help'],
    testId: 'help-button',
    hoverImage: undefined,
    userType: 'CF',
    visibleOnDashboard: false,
  }
];

const menuItemsCF: MenuItemsType = [];
const fill = () => {
  // reset array contents with fresh translated values
  menuItemsCF.length = 0;
  menuItemsCF.push(...menuCF);
};

i18n.on('languageChanged init', () => {
  fill();
});

export default menuItemsCF;

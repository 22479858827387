import { ManageUserStoreActionType } from '../types/storeActions';
import { ManageUserStoreType } from '../types/stores';

const initialState: ManageUserStoreType = {
  usersToDelete: [],
  selectedUserIndex: 0,
};

export const initialManageUserState: ManageUserStoreType = {
  usersToDelete: [],
  selectedUserIndex: 0,
};

const manageUserStore = (
  state = initialState,
  action: ManageUserStoreActionType
) => {
  switch (action.type) {
    case 'SET_USERS_TO_DELETE':
      return {
        ...state,
        usersToDelete: action.payload,
      };
    case 'SET_SELECTED_USER_INDEX':
      return {
        ...state,
        selectedUserIndex: action.payload,
      };
    default:
      return state;
  }
};

export default manageUserStore;

import { AwsInstance } from '../auth/hooks/awsManager';
import { getApiErrorMessage } from './handleApiErrors';

const apiUrl = process.env.REACT_APP_REST_URL;

const fetchCall = (
  url: string,
  options?: RequestInit,
  ignoreResults?: boolean
) => {
  const token = AwsInstance.getInstance().getToken();
  const headersContent = token ? { Authorization: token } : null;

  const resultUrl = url.includes('https') ? url : `${apiUrl}${url}`;

  return fetch(encodeURI(resultUrl), {
    ...options,
    headers: {
      ...headersContent,
      'Content-Type': 'application/json',
    },
  })
    .catch(() => {
      // According to the doc this error handles only network errors created by the fetch
      throw Error(getApiErrorMessage('network'));
    })
    .then((response: Response) => {
      if (!response.ok && response.status !== 404) {
        if (response.status === 409) {
          return response?.json().then((error) => {
            throw Error(error?.message);
          });
        }

        throw Error(getApiErrorMessage(response?.status));
      }

      if (options?.method === 'DELETE' || ignoreResults) {
        return Promise.resolve();
      }

      return response.json();
    });
};

export const getRequest = (url: string) => fetchCall(url);

export const postRequest = (
  url: string,
  data: object,
  ignoreResults?: boolean
) =>
  fetchCall(url, { method: 'POST', body: JSON.stringify(data) }, ignoreResults);

export const patchRequest = (url: string, data: object) =>
  fetchCall(url, { method: 'PATCH', body: JSON.stringify(data) });

export const deleteRequest = (url: string) =>
  fetchCall(url, { method: 'DELETE' });

export const rejectRequest = (message: string) => Promise.reject({ message });

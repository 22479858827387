import React from 'react';

import KdDayPickerView, { KdDayPickerDefaultProps } from './KdDayPickerView';

interface Props extends Partial<KdDayPickerDefaultProps> {
  selected: Date | undefined;
  mode?: 'single';
}

const KdDaySinglePicker: React.FC<Props> = ({
  className,
  disabled,
  numberOfDaysBetween,
  selected,
  onDayClick,
  testId,
  mode = 'single',
}) => (
  <KdDayPickerView
    className={className}
    disabled={disabled}
    mode={mode}
    numberOfDaysBetween={numberOfDaysBetween}
    onDayClick={onDayClick}
    selected={selected}
    testId={testId}
  />
);

export default KdDaySinglePicker;

import cn from 'classnames';
import React from 'react';

import { KdDropdownType } from '../../types/kdDropdown';
import KdValidationMessage from '../Forms/KdValidationMessage';
import KdDropdownItem from './KdDropdownItem';
import KdDropdownMenu from './KdDropdownMenu';
import KdToggleButton from './KdToggleButton';
import useKdDropdown from './hooks/useKdDropdown';

const KdDropdown = React.forwardRef<HTMLDivElement, KdDropdownType>(
  (
    {
      buttonClassName,
      className,
      disabled,
      defaultMenuValue,
      error,
      id,
      isArrowHidden,
      isReadOnly,
      menuClassName,
      onChange,
      options,
      placeholder,
      testId,
      value,
    },
    ref
  ) => {
    const {
      buttonRef,
      buttonValue,
      handleSelectValue,
      handleToggle,
      menuRef,
      selectedValue,
      show,
    } = useKdDropdown({
      defaultMenuValue,
      onChange,
      options,
      placeholder,
      value,
    });

    return (
      <div
        className={cn('relative', className)}
        data-testid={testId}
        ref={ref}
      >
        <KdToggleButton
          className={buttonClassName}
          disabled={disabled}
          id={id}
          isArrowHidden={isArrowHidden}
          isReadOnly={isReadOnly}
          onClick={handleToggle}
          show={show}
          value={buttonValue || 'Button'}
          ref={buttonRef}
        />
        {show && (
          <KdDropdownMenu
            ref={menuRef}
            className={menuClassName}
          >
            {options?.map((option) => (
              <KdDropdownItem
                testid={`${testId}-${option.key}`}
                key={`option${option.key}`}
                onClick={() => handleSelectValue(option.key)}
                isActive={option.key === selectedValue}
                label={option.label}
                isDefaultMenuValue={
                  !selectedValue && option.key === defaultMenuValue
                }
              />
            ))}
          </KdDropdownMenu>
        )}
        {error && <KdValidationMessage error={error} />}
      </div>
    );
  }
);

export default KdDropdown;

import { t } from 'i18next';
import { useMemo, useRef, useState } from 'react';

import { ReactComponent as HelpImg } from '../../../assets/images/help.svg';
import { ReactComponent as InfoImg } from '../../../assets/images/info.svg';
import { ReactComponent as LogoutImg } from '../../../assets/images/logout.svg';
import { useAuth } from '../../../auth/hooks/useAuth';
import useHandleClickOutside from '../../../hooks/useHandleClickOutside';
import { TopbarButtonType } from '../ui/HeaderDesktop';

const useHeaderDesktop = () => {
  const { user, signOut } = useAuth();

  const [show, setShow] = useState(false);
  const clickOutsideRef = useRef(null);

  const handleClickOutside = () => {
    setShow(false);
  };

  useHandleClickOutside({
    ref: clickOutsideRef,
    onClick: handleClickOutside,
  });

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleClick = () => {
    setShow((prev) => !prev);
  };

  const menuItems: Array<TopbarButtonType> = useMemo(
    () => [
      {
        title: t('Help'),
        path: '/help',
        icon: HelpImg,
      },
      {
        title: t('About'),
        path: '/about',
        icon: InfoImg,
      },
      {
        title: t('Logout'),
        onClick: signOut,
        icon: LogoutImg,
        size: 'small',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    menuItems,
    selectedIndex,
    setSelectedIndex,
    clickOutsideRef,
    handleClick,
    user,
    show,
    handleClickOutside,
  };
};
export default useHeaderDesktop;

import { useNavigate } from 'react-router-dom';

import { resetSiteData } from '../../../auth/functions/resetSiteData';

const useErrorPage = () => {
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate('/');
  };

  const handleClearCache = () => {
    document.body.style.cursor = 'wait';
    setTimeout(() => {
      resetSiteData({
        clearLocalStorage: true,
        clearCookies: true,
        clearSessionStorage: true,
        reload: false,
      });
      navigate('/');
      window.location.reload();
      document.body.style.cursor = 'default';
    }, 100);
  };

  return { handleReturnHome, handleClearCache };
};

export default useErrorPage;

import Joi from 'joi';

import { regexp } from '../../data/regexp';
import { UserTypeEnum } from '../../types/enums';

export const getUsersScheme = Joi.object({
  userGroup: Joi.string()
    .valid(...Object.values(UserTypeEnum))
    .label('User Group')
    .messages({ 'any.only': '{{#label}} is invalid' }),
});

export const userScheme = Joi.object({
  id: Joi.string().required().pattern(regexp.id).label('User ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
});

export const createUserScheme = Joi.object({
  fullName: Joi.string()
    .required()
    .pattern(regexp.fullName)
    .label('User Name')
    .messages({
      'string.pattern.base': '{{#label}} is invalid',
    }),
  userType: Joi.string()
    .required()
    .valid(...Object.values(UserTypeEnum))
    .label('User Type')
    .messages({
      'any.only': '{{#label}} is invalid',
    }),
  temporaryPassword: Joi.string().required().label('Password'),
  email: Joi.string().pattern(regexp.email).label('Email').messages({
    'string.pattern.base': '{{#label}} is required',
  }),
});

export const updateUserScheme = Joi.object({
  id: Joi.string().required().pattern(regexp.id).label('User ID').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
  name: Joi.string()
    .required()
    .pattern(regexp.fullName)
    .label('User Name')
    .messages({
      'string.pattern.base': '{{#label}} is invalid',
    }),
});

export const checkEmailScheme = Joi.object({
  email: Joi.string().required().pattern(regexp.email).label('Email').messages({
    'string.pattern.base': '{{#label}} is invalid',
  }),
});

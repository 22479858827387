import { useCallback, useState } from 'react';

const useModal = () => {
  const [show, setShow] = useState<boolean>(false);

  const showModal = useCallback(() => {
    setShow(true);
  }, [setShow]);

  const closeModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return { closeModal, show, showModal };
};

export default useModal;

import { t } from 'i18next';
import React from 'react';

import { KdDropdownType } from '../../../types/kdDropdown';
import KdDropdown from '../../Dropdown/KdDropdown';
import KdDropdownItem from '../../Dropdown/KdDropdownItem';
import KdDropdownMenu from '../../Dropdown/KdDropdownMenu';
import useKdSorting from '../hooks/useKdSorting';

type Props = KdDropdownType & {
  /** Determines if the dropdown option is selected */
  isActive?: boolean;
  /** Determines if the dropdown is open */
  isShow?: boolean;
  /** Determines if there is a small sreen size */
  isSmallScreen?: boolean;
  /** The onChange handler, takes an option key as a parameter */
  onFilterChange?: (value: string) => void;
  /** The test id of the sorting, used for Cypress testing to locate the input */
  testId?: string;
};

const KdSorting: React.FC<Props> = ({
  defaultMenuValue,
  isActive,
  isShow,
  isSmallScreen,
  onFilterChange,
  options,
  placeholder,
  testId,
  ...props
}) => {
  const { selectedValue, handleFilterChange } = useKdSorting({
    onFilterChange,
    defaultMenuValue,
  });

  return (
    <>
      {isSmallScreen ? (
        <>
          {isShow && (
            <KdDropdownMenu className="sm:w-[150px] sm:right-0 sm:left-[inherit]">
              {options?.map((option) => (
                <KdDropdownItem
                  key={option.key}
                  label={option.label}
                  onClick={() => handleFilterChange(option.key)}
                  isActive={option.key === selectedValue}
                  isDefaultMenuValue={
                    !selectedValue && option.key === defaultMenuValue
                  }
                />
              ))}
            </KdDropdownMenu>
          )}
        </>
      ) : (
        <KdDropdown
          {...props}
          className="w-[150px] ml-[20px]"
          defaultMenuValue={defaultMenuValue}
          onChange={handleFilterChange}
          options={options}
          placeholder={placeholder || t('Sort by')}
          testId={testId}
        />
      )}
    </>
  );
};
export default KdSorting;

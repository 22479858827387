import { getRequest, rejectRequest } from '../fetch';
import { BayInfoRequestType, BaysRequestType } from '../types';
import {
  getBayAvailabilityScheme,
  getBayHoursScheme,
  getBayScheme,
  getBaysScheme,
} from '../validation';

export const callGetBaysForArea = ({
  lat,
  lng,
  radius,
  limit,
  page,
}: BaysRequestType) => {
  const { error } = getBaysScheme.validate({ lat, lng, radius, limit, page });

  if (error) {
    return rejectRequest(error?.message);
  }

  return getRequest(
    `/paging/bays?lat=${lat}&lon=${lng}&radius=${radius}&limit=${limit}&page_number=${page}`
  );
};

export const callGetBayByID = ({ id }: { id: string }) => {
  const { error } = getBayScheme.validate({ id });

  if (error) {
    return rejectRequest(error?.message);
  }

  return getRequest(`/bays/${id}`);
};

export const callGetBayHoursOfOperation = ({
  id,
  date,
}: BayInfoRequestType) => {
  const { error } = getBayHoursScheme.validate({ id, date });

  if (error) {
    return rejectRequest(error?.message);
  }

  return getRequest(`/bays/${id}/hours?local_date=${date}`);
};

export const callGetBayAvailability = ({ id, date }: BayInfoRequestType) => {
  const { error } = getBayAvailabilityScheme.validate({ id, date });

  if (error) {
    return rejectRequest(error?.message);
  }

  return getRequest(`/bays/${id}/availability?date=${date}`);
};

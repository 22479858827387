import { t } from 'i18next';

type Props = {
  [key: string]: {
    title: string;
    featureFlagId?: string;
  };
};

const routesInfo: Props = {
  landing: {
    title: t('Organisation Type'),
  },
  login: {
    title: t('Login'),
  },
  'login-la': {
    title: t('Login (LA)'),
    featureFlagId: 'laUserFlow',
  },
  register: {
    title: t('Register'),
  },
  confirm: {
    title: t('Confirmation Code'),
  },
  resetpassword: {
    title: t('Reset Password'),
  },
  newpassword: {
    title: t('New Password'),
  },
  dashboard: {
    title: t('Dashboard'),
  },
  about: {
    title: t('About'),
  },
  bookings: {
    title: t('Bookings'),
  },
  createbooking: {
    title: t('Create a Booking'),
  },
  editbooking: {
    title: t('Manage Booking'),
  },
  editorganisation: {
    title: t('Edit Organisation'),
  },
  managevehicles: {
    title: t('Manage Fleet'),
  },
  manageusers: {
    title: t('Manage Users'),
  },
  help: {
    title: t('Help'),
  },
  'analytics-cf': {
    title: t('Analytics'),
  },
  'analytics-la': {
    title: t('Analytics'),
  },
  addvehicle: {
    title: t('Add to Fleet'),
    featureFlagId: 'addVehicleRoute',
  },
  adduser: {
    title: t('Add New User'),
  },
};

export default routesInfo;

import { Auth } from 'aws-amplify';

type ClearDataOptionsType = {
  clearLocalStorage?: boolean;
  clearCookies?: boolean;
  clearSessionStorage?: boolean;
  reload?: boolean;
  preserveUserType?: boolean;
  callLogout?: boolean;
};

export const resetSiteData = ({
  clearLocalStorage = true,
  clearCookies = true,
  clearSessionStorage = true,
  reload = true,
  preserveUserType = false,
  callLogout = true,
}: ClearDataOptionsType) => {
  if (Auth && callLogout) Auth?.signOut();
  if (clearLocalStorage) {
    wipeLocalStorage(preserveUserType);
  }
  if (clearCookies) {
    killAllCookies();
  }
  if (clearSessionStorage) {
    sessionStorage.clear();
  }
  if (reload) {
    window.location.reload();
  }
};

const wipeLocalStorage = (preserveUserType: boolean = false) => {
  if (!preserveUserType) {
    localStorage.clear();
    return;
  }

  // preserve user type and clear the rest of localstorage
  const userType = localStorage.getItem('userType');
  localStorage.clear();
  localStorage.setItem('userType', userType as string);
};

const killAllCookies = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, `$=;expires=' ${new Date().toUTCString()};path=/`);
  });
};

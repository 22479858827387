import { MenuItemsType } from '../types/menuTypes';

const padding = 20;
const maxItemsLength = 5;

export const getMenuItemWidth = (menuItems: MenuItemsType, width: number) => {
  const menuItemsLength = menuItems.length + 1;
  const itemWidth =
    (width - padding) /
    (menuItemsLength < maxItemsLength ? menuItemsLength : maxItemsLength);

  return itemWidth;
};

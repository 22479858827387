import cn from 'classnames';
import { t } from 'i18next';
import React from 'react';

import KdButton from './KdButton';

type Props = {
  /** The additional class name of the button group, used for customising the existing style and tweaking the look and feel */
  className?: string;
  /** Determines if the Go back button is visible */
  isBackButton?: boolean;
  /** Determines if the buttons have another order on mobile */
  isReverseButtons?: boolean;
  /** The onClick handler for the Go back button */
  onBackClick?: () => void;
  /** Renders any buttons */
  renderButtons: React.ReactNode;
};

const KdButtonGroup: React.FC<Props> = ({
  className,
  isBackButton = true,
  isReverseButtons = true,
  onBackClick,
  renderButtons,
}) => {
  const classes = cn(
    'flex sm:justify-center gap-[15px] sm:gap-[30px] mt-[30px]',
    { 'flex-col-reverse sm:flex-row': isReverseButtons },
    className
  );

  return (
    <div className={classes}>
      {isBackButton && (
        <KdButton
          variant="tertiary"
          onClick={onBackClick}
          fullOnMobile
        >
          {t('Go Back')}
        </KdButton>
      )}
      {renderButtons}
    </div>
  );
};

export default KdButtonGroup;

import {
  externalErrorMessage,
  networkErrorMessage,
} from '../api/handleApiErrors';
import useRedirect from './useRedirect';

const useHandleApiErrorRedirect = () => {
  const { goBack } = useRedirect();

  const apiErrorRedirect = (err: Error) => {
    if (
      err?.message === networkErrorMessage ||
      err?.message === externalErrorMessage
    ) {
      goBack();
    }
  };

  return { apiErrorRedirect };
};
export default useHandleApiErrorRedirect;

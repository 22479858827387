// @ts-nocheck
import { ReactComponent as mapEvMarkerIcon } from '../../assets/images/EVBayMarker.svg';
import { ReactComponent as mapEvCityIcon } from '../../assets/images/EVKerbMapIcon.svg';
import { SchemaFiles } from '../../types/schema';
import UKNumberPlateWoff from './fonts/UKNumberPlate.woff';
import UKNumberPlateWoff2 from './fonts/UKNumberPlate.woff2';
import titleFontWoff from './fonts/opensans-regular-webfont.woff';
import titleFontWoff2 from './fonts/opensans-regular-webfont.woff2';
import { ReactComponent as alertIcon } from './images/alert-icon.svg';
import favicon192 from './images/android-chrome-192x192.png';
import favicon512 from './images/android-chrome-512x512.png';
import appleIcon from './images/apple-touch-icon.png';
import backgroundImage from './images/background-image.jpg';
import { ReactComponent as carNumber } from './images/car-number.svg';
import favicon16 from './images/favicon-16x16.png';
import favicon32 from './images/favicon-32x32.png';
import favicon from './images/favicon.ico';
import { ReactComponent as leafIcon } from './images/leaf.svg';
import logoSmall from './images/logo-small.png';
import { ReactComponent as mapClusterIcon } from './images/map-cluster.svg';
import { ReactComponent as mapMarkerIcon } from './images/map-marker.svg';
import { ReactComponent as mapCityIcon } from './images/mapCityIcon.svg';
import msTile from './images/mstile-150x150.png';
import { ReactComponent as primaryLogo } from './images/primaryLogo.svg';
import { ReactComponent as questionIcon } from './images/question-icon.svg';
import { ReactComponent as secondaryLogo } from './images/secondaryLogo.svg';
import { ReactComponent as successIcon } from './images/success-icon.svg';

const schemaFiles: SchemaFiles = {
  images: {
    logoSmall,
    primaryLogo,
    secondaryLogo,
    favicon,
    favicon16,
    favicon32,
    favicon192,
    favicon512,
    appleIcon,
    msTile,
    alertIcon,
    questionIcon,
    successIcon,
    backgroundImage,
    mapMarkerIcon,
    mapCityIcon,
    mapEvMarkerIcon,
    mapClusterIcon,
    mapEvCityIcon,
    backgroundImageInDashboard: true,
    backgroundImageInLanding: true,
    backgroundImageInLoading: true,
    carNumber,
    leafIcon,
  },
  fonts: {
    titleFontWoff,
    titleFontWoff2,
    primaryFontWoff: titleFontWoff,
    primaryFontWoff2: titleFontWoff2,
    UKNumberPlateWoff,
    UKNumberPlateWoff2,
  },
};

export default schemaFiles;

const cacheMap = new Map();

export const hasCachedKey = (key: string) => cacheMap.has(key);

export const getCache = (key: string) => cacheMap.get(key);

export const setCache = (key: string, value: unknown) =>
  cacheMap.set(key, value);

export const deleteCachedKey = (key: string) => cacheMap.delete(key);

export const clearCache = () => cacheMap.clear();

export const verifyCacheOwnership = (organisationId: string) => {
  if (getCache('organisationId') !== organisationId) {
    clearCache();
  }
  setCache('organisationId', organisationId);
};

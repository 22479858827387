import { RefObject, useEffect } from 'react';

type Props = {
  ref: RefObject<HTMLElement | null>;
  onClick: VoidFunction;
};

const useHandleClickOutside = ({ ref, onClick }: Props) => {
  useEffect(() => {
    const handleClickOutside = (ev: MouseEvent): void => {
      if (ref.current && !ref.current.contains(ev.target as Node)) {
        onClick();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export default useHandleClickOutside;

import cn from 'classnames';
import { t } from 'i18next';
import React from 'react';

interface Props {
  className?: string;
  pageTitle: string;
}

const HeaderPageTitle: React.FC<Props> = ({ className, pageTitle }) => {
  const classes = cn(
    'text-[18px] sm:text-[20px] font-semibold text-contentColor ellipsis',
    className
  );

  return <h1 className={classes} title={t(pageTitle)}>{t(pageTitle)}</h1>;
};

export default HeaderPageTitle;

import { ReactComponent as MoreIcon } from '../../../assets/images/more.svg';
import useMenuMoreButton from '../hooks/useMenuMoreButton';
import { MenuItemsType } from '../types/menuTypes';
import CollapseMenu from './CollapseMenu';

interface Props {
  itemWidth: number;
  isMoreButtonActive: boolean;
  menuItems: MenuItemsType;
  onClick: () => void;
}

const MenuMoreButton = ({
  itemWidth,
  isMoreButtonActive,
  menuItems,
  onClick,
}: Props) => {
  const { handleHide, handleShow, show } = useMenuMoreButton({ onClick });

  return (
    <>
      <button
        className={`${isMoreButtonActive ? 'text-white' : 'text-gray-300'}`}
        data-testid="bottom-navigation-more-button"
        onClick={handleShow}
        style={{ width: `${itemWidth}px` }}
        type="button"
      >
        <MoreIcon
          className="mx-auto"
          data-testid="bottom-navigation-more-image"
        />
      </button>
      <CollapseMenu
        menuItems={menuItems}
        onClose={handleHide}
        show={show}
      />
    </>
  );
};

export default MenuMoreButton;

import { getRequest, postRequest, rejectRequest } from '../fetch';
import { HelpRequestType } from '../types';
import { createHelpScheme } from '../validation';

export const callCreateHelpTicket = ({
  message,
  addressee,
  subject,
}: HelpRequestType) => {
  const { error } = createHelpScheme.validate({
    message,
    addressee,
    subject,
  });

  if (error) {
    return rejectRequest(error?.message);
  }

  return postRequest(
    '/support',
    {
      subject,
      message,
      support_addressee: addressee,
    },
    true
  );
};

export const callGetDrivers = () => getRequest('/users?user_group=Driver');

export const callCheckMinVersion = () => getRequest('/versions');

import cn from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  href?: string;
  icon: React.ReactNode;
  onClick?: () => void;
  title: string;
}

const CollapseMenuItem = ({ href, icon, onClick, title }: Props) => {
  const location = useLocation();

  const classes = cn(
    'flex items-center font-semibold py-[20px]',
    location.pathname === href ? 'text-accentPrimary' : 'text-white'
  );

  const renderContent = (
    <>
      <span className="firstChild:w-[20px] mr-[30px]">{icon}</span>
      {title}
    </>
  );

  if (!href) {
    return (
      <li>
        <button
          className={classes}
          onClick={onClick}
          type="button"
        >
          {renderContent}
        </button>
      </li>
    );
  }

  return (
    <li>
      <a
        className={classes}
        href={`/#${href}`}
      >
        {renderContent}
      </a>
    </li>
  );
};

export default CollapseMenuItem;

import { RouterProvider } from 'react-router-dom';

import { SchemaColours, SchemaFiles } from '../types/schema';
import routes from './routes';

const schemaFiles: SchemaFiles =
  require(`./../theme/${process.env.REACT_APP_THEME}/schemaFiles.tsx`).default;
const schemaColours: SchemaColours = require(`./../theme/${process.env.REACT_APP_THEME}/schemaColours.json`);

const AppRouter = () => {
  const bgStyle =
    schemaFiles.images.backgroundImage &&
    schemaFiles.images.backgroundImageInDashboard
      ? {
          backgroundImage: `url(${schemaFiles.images.backgroundImage})`,
        }
      : {
          backgroundColor: schemaColours.backgroundColor,
        };

  return (
    <div
      className="bg-backgroundColor bg-fixed bg-no-repeat bg-cover bg-center h-[100vh]"
      style={bgStyle}
    >
      <RouterProvider router={routes} />
    </div>
  );
};

export default AppRouter;

import { ReactComponent as HighlightIcon } from '../../../assets/images/light.svg';
import useHighlightMobile from '../hooks/useHighlightMobile';

interface Props {
  itemWidth: number;
  isMoreButtonActive?: boolean;
  selectedIndex: number;
}

const HighlightMobile = ({
  itemWidth,
  isMoreButtonActive,
  selectedIndex,
}: Props) => {
  const { left } = useHighlightMobile({
    itemWidth,
    isMoreButtonActive,
    selectedIndex,
  });
  return (
    <HighlightIcon
      className="absolute -rotate-90 pointer-events-none transition-all duration-1000"
      width="70"
      height="70"
      style={{
        left,
        top: -4,
      }}
    />
  );
};

export default HighlightMobile;

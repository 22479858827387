import { useRouteError } from 'react-router-dom';

import ErrorPage from '../pages/ErrorPage/ErrorPage';

export const RouterErrorBoundary = () => {
  const error = useRouteError();

  if (error) {
    console.error(`[Error]: ${error}`);
    return <ErrorPage />;
  }

  return null;
};

import { useEffect, useState } from 'react';

import { convertBreakpointStringToNumber } from '../functions/utils';
import { DesignTokensType } from '../types/designTokens';

const tokens: DesignTokensType = require('../theme/design-tokens.json');

const largeScreenBreakpoint = convertBreakpointStringToNumber(
  tokens.screens.lg
);

export const mobileBreakpoint = convertBreakpointStringToNumber(
  tokens.screens.sm
);

export const smallScreenBreakpoint = convertBreakpointStringToNumber(
  tokens.screens.md
);

export const mediumScreenBreakpoint = convertBreakpointStringToNumber(
  tokens.screens.xl
);

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    isSmallScreen: width < smallScreenBreakpoint,
    isMediumScreen: width < mediumScreenBreakpoint,
    isMobile: width < mobileBreakpoint,
    isSmallerThanLarge: width < largeScreenBreakpoint,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
export default useWindowDimensions;

import { useMemo } from 'react';

const maxItemsLength = 5;
const svgSize = 27;

const useHighlightMobile = ({
  itemWidth,
  isMoreButtonActive,
  selectedIndex,
}: {
  itemWidth: number;
  isMoreButtonActive?: boolean;
  selectedIndex: number;
}) => {
  const left = useMemo(() => {
    const imageOffset = svgSize / 2;

    return isMoreButtonActive
      ? (maxItemsLength - 1) * itemWidth + imageOffset
      : selectedIndex * itemWidth + imageOffset;
  }, [itemWidth, isMoreButtonActive, selectedIndex]);

  return { left };
};
export default useHighlightMobile;

import cn from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const KdDropdownMenu = React.forwardRef<HTMLDivElement, Props>(
  ({ className, children }, ref) => {
    const classes = cn(
      `border-controlWidth
     border-borderColor
     bg-controlBg
     px-[20px] 
     py-[10px] 
     rounded-base
     w-full
     nextChild:mt-[12px]
     z-10
     mt-[5px]
     sm:absolute 
     sm:top-[calc(100%+5px)]
     sm:left-0
     sm:m-0`,
      className
    );

    return (
      <div
        className={classes}
        role="menu"
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export default KdDropdownMenu;

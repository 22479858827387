import { Suspense, useEffect } from 'react';
import {
  Navigate,
  Outlet,
  useLocation,
  useOutletContext,
} from 'react-router-dom';

import { useAuth } from '../auth/hooks/useAuth';
import useWindowDimensions from '../hooks/useWindowDimensions';
import SuspenseFallbackPrivate from '../pages/SuspenseFallback/SuspenseFallbackPrivate';
import KdMenu from '../shared/Menu/KdMenu';
import TopBar from '../shared/TopBar/TopBar';
import { OutletContextType } from '../types/routes';

type Props = {
  isMenuAndHeader?: boolean;
};

const PrivateRoute = ({ isMenuAndHeader = true }: Props) => {
  const { isLoggedIn, assertUserLogin } = useAuth();
  const location = useLocation();

  const { pageTitle } = useOutletContext<OutletContextType>();
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    const securityCheck = async () => {
      assertUserLogin?.();
    };
    securityCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn) {
    return (
      <Navigate
        to="/"
        state={{ from: location.pathname }}
      />
    );
  }

  return (
    <>
      {isMenuAndHeader && (
        <>
          <TopBar pageTitle={pageTitle} />
          <KdMenu type={isMobile ? 'mobile' : 'desktop'} />
        </>
      )}
      <Suspense fallback={<SuspenseFallbackPrivate />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export default PrivateRoute;

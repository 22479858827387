import { getRequest, rejectRequest } from '../fetch';
import { AnalyticsRequestType, AnalyticsType } from '../types';
import { analyticsScheme } from '../validation';

const callGetAnalytics = ({ url, startDate, endDate }: AnalyticsType) => {
  const { error } = analyticsScheme.validate({ startDate, endDate });

  if (error) {
    return rejectRequest(error?.message);
  }

  return getRequest(url);
};

export const callGetBookingsByDriver = ({
  startDate,
  endDate,
}: AnalyticsRequestType) =>
  callGetAnalytics({
    url: `/reporting/bookings_by_driver?start=${startDate}&end=${endDate}`,
    startDate,
    endDate,
  });

export const callGetCompaniesWithBookings = ({
  startDate,
  endDate,
}: AnalyticsRequestType) =>
  callGetAnalytics({
    url: `/reporting/companies_with_bookings?start=${startDate}&end=${endDate}`,
    startDate,
    endDate,
  });

export const callGetBookingByTime = ({
  startDate,
  endDate,
}: AnalyticsRequestType) =>
  callGetAnalytics({
    url: `/reporting/bookings_by_time?start=${startDate}&end=${endDate}`,
    startDate,
    endDate,
  });

export const callGetBookingsByVrmBay = ({
  startDate,
  endDate,
}: AnalyticsRequestType) =>
  callGetAnalytics({
    url: `/reporting/bookings_by_vrm_bay?start=${startDate}&end=${endDate}`,
    startDate,
    endDate,
  });

export const callGetRogueVehicleByTime = ({
  startDate,
  endDate,
}: AnalyticsRequestType) =>
  callGetAnalytics({
    url: `/reporting/rogue_by_time?start=${startDate}&end=${endDate}`,
    startDate,
    endDate,
  });

export const callGetBookingsByVehicle = ({
  startDate,
  endDate,
}: AnalyticsRequestType) =>
  callGetAnalytics({
    url: `/reporting/bookings_by_vrm?start=${startDate}&end=${endDate}`,
    startDate,
    endDate,
  });

export const callBayUsage = ({ startDate, endDate }: AnalyticsRequestType) =>
  callGetAnalytics({
    url: `/reporting/bay_usage?start=${startDate}&end=${endDate}`,
    startDate,
    endDate,
  });

export const callGetBookingsByDuration = ({
  startDate,
  endDate,
}: AnalyticsRequestType) =>
  callGetAnalytics({
    url: `/reporting/bookings_by_duration?start=${startDate}&end=${endDate}`,
    startDate,
    endDate,
  });

export const callExportAllReports = ({
  startDate,
  endDate,
}: AnalyticsRequestType) =>
  callGetAnalytics({
    url: `/reporting/export_all_reports?start=${startDate}&end=${endDate}`,
    startDate,
    endDate,
  });

import cn from 'classnames';
import useInitialsBox from '../hooks/useInitialsBox';

const sizeClasses = (size: string) => {
  switch (size) {
    case 'normal':
      return 'min-w-[83px] max-w-[83px] min-h-[83px] text-[40px] tracking-[1px]';
    case 'medium':
      return 'min-w-[123px] max-w-[123px] min-h-[123px] text-[50px] tracking-[2px]';
    case 'big':
      return 'min-w-[147px] max-w-[147px]  min-h-[147px] text-[70px] tracking-[5px]';
    case 'small':
      return 'min-w-[50px] max-w-[50px]  min-h-[50px] text-[20px]';
    case 'topbar':
      return 'min-w-[55px] max-w-[55px]  min-h-[55px] text-[26px]';
    default:
      return '';
  }
};

const InitialsBox = ({
  userName,
  index,
  size = 'normal',
}: {
  userName: string;
  index: number;
  size?: 'normal' | 'medium' | 'big' | 'small' | 'topbar';
}) => {
  const classes = cn(
    `flex justify-center items-center  rounded-xl`,
    sizeClasses(size)
  );

  const { cardColor, cardTextColor, getInitials } = useInitialsBox({ index });



  return (
    <div
      className={classes}
      style={{
        backgroundColor: cardColor,
        color: cardTextColor,
      }}
    >
      <span
        style={{
          fontWeight: 900,
        }}
      >
        {getInitials(userName)}
      </span>
    </div>
  );
};
export default InitialsBox;

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useBackButtonHandler from '../../../hooks/useBackButtonHandler';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import KdButton from '../../KdButton';

const BackButton: React.FC = () => {
  const { t } = useTranslation();
  const { redirectToPreviousPage } = useBackButtonHandler();
  const { isMobile } = useWindowDimensions();

  return (
    <>
      {isMobile ? (
        <button
          className="absolute left-[20px]"
          onClick={redirectToPreviousPage}
          type="button"
        >
          <ArrowBackIcon className="w-[25px] h-[25px]" />
        </button>
      ) : (
        <KdButton
          onClick={redirectToPreviousPage}
          size="small"
        >
          {t('Back')}
        </KdButton>
      )}
    </>
  );
};

export default BackButton;

import { ViewStoreActionType } from '../types/storeActions';
import { ViewStoreType } from '../types/stores';

export const initialViewState: ViewStoreType = {
  subRouteProgress: 0,
  subRouteData: [],
  headerButton: null,
  dataOutOfSync: false,
  toastData: {
    content: null,
    variant: 'error',
    isDisplayed: false,
    noTopBar: false,
  },
  modalData: {
    show: false,
    variant: 'warning',
  },
  selectedHighlight: { upper: -1, lower: -1 },
  hashStatus: '',
};

const viewStore = (
  state = initialViewState,
  action: ViewStoreActionType
): ViewStoreType => {
  switch (action.type) {
    case 'SET_SUB_ROUTE_PROGRESS':
      return {
        ...state,
        subRouteProgress: action.payload,
      };
    case 'SET_CURRENT_SUBROUTES_DATA_ARRAY':
      return {
        ...state,
        subRouteData: action.payload,
      };
    case 'SET_HEADER_BUTTON_COMPONENT':
      return {
        ...state,
        headerButton: action.payload,
      };
    case 'SET_DATA_OUT_OF_SYNC':
      return {
        ...state,
        dataOutOfSync: action.payload,
      };
    case 'SET_TOAST_DATA':
      return {
        ...state,
        toastData: action.payload,
      };
    case 'SET_SELECTED_HIGHLIGHT':
      return {
        ...state,
        selectedHighlight: action.payload,
      };
    case 'SET_MODAL_DATA':
      return {
        ...state,
        modalData: action.payload,
      };
    case 'SET_HASH_STATUS':
      return {
        ...state,
        hashStatus: action.payload,
      };
    default:
      return state;
  }
};

export default viewStore;

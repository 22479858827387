import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import React from 'react';

import { SchemaColours } from '../../types/schema';

const schemaColours: SchemaColours = require(`../../theme/${process.env.REACT_APP_THEME}/schemaColours.json`);

type Props = CheckboxProps & {
  /** The size of the checkbox */
  boxSize?: number;
  /** The colour for the checked checkbox */
  colorPrimary?: string;
  /** The colour for the unchecked checkbox */
  colorSecondary?: string;
  /** The test id of the checkbox, used for Cypress testing to locate the checkbox */
  testId?: string;
};

const KdCheckbox: React.FC<Props> = ({
  boxSize = 28,
  colorPrimary = schemaColours.accentPrimary,
  colorSecondary = schemaColours.secondary,
  testId,
  ...props
}) => (
  <Checkbox
    data-testid={testId}
    {...props}
    sx={{
      color: colorSecondary,
      '& .MuiSvgIcon-root': {
        fontSize: boxSize,
      },
      '&.Mui-checked': {
        color: colorPrimary,
      },
    }}
  />
);

export default KdCheckbox;

import cn from 'classnames';
import React from 'react';
import {
  DateFormatter,
  DayContent,
  DayContentProps,
  DayPicker,
  DayPickerBase,
  DayPickerRangeProps,
  DayPickerSingleProps,
  Matcher,
} from 'react-day-picker';

export interface KdDayPickerDefaultProps extends DayPickerBase {
  className?: string;
  disabled?: Matcher | Matcher[];
  numberOfDaysBetween?: number;
  testId?: string;
  mode: 'range' | 'single';
}

interface KdDayPickerRangeProps extends DayPickerRangeProps {
  className?: string;
  disabled?: Matcher | Matcher[];
  numberOfDaysBetween?: number;
  testId?: string;
  mode: 'range';
}

interface KdDayPickerSingleProps extends DayPickerSingleProps {
  className?: string;
  disabled?: Matcher | Matcher[];
  numberOfDaysBetween?: number;
  testId?: string;
  mode: 'single';
}

type KdDayPickerViewType =
  | KdDayPickerDefaultProps
  | KdDayPickerSingleProps
  | KdDayPickerRangeProps;

const formatWeekdayName: DateFormatter = (date) => {
  const day = date.toString().split(' ')[0];
  return <>{day}</>;
};

const KdDayPickerView: React.FC<KdDayPickerViewType> = ({
  className,
  disabled,
  numberOfDaysBetween,
  testId,
  mode,
  ...props
}) => {
  const classes = cn('z-10 my-[20px]', className);
  const endDayClasses = cn('daypicker-day-end', {
    'daypicker-day-end_close': numberOfDaysBetween === 1,
  });

  const commonProps = {
    ...props,
    captionLayout: 'dropdown',
    disabled,
    numberOfMonths: 1,
    pagedNavigation: true,
    weekStartsOn: 1,
    formatters: {
      formatWeekdayName,
    },
    components: {
      DayContent: (props: DayContentProps) => (
        <span>
          <DayContent {...props} />
        </span>
      ),
    },
    classNames: {
      root: 'daypicker',
      months: '',
      caption: 'daypicker-caption',
      caption_label: 'daypicker-caption__label',
      nav_button_previous: 'daypicker-nav-btn daypicker-nav-btn_prev',
      nav_button_next: 'daypicker-nav-btn daypicker-nav-btn_next',
      table: 'daypicker-table',
      day_selected: 'daypicker-day-selected',
      day_range_start: 'daypicker-day-start',
      day_range_end: endDayClasses,
      day_range_middle: 'daypicker-day-middle',
    },
  };

  return (
    <div
      className={classes}
      data-testid={testId}
    >
      {mode === 'single' ? (
        <DayPicker
          {...(commonProps as DayPickerSingleProps)}
          mode="single"
        />
      ) : (
        <DayPicker
          {...(commonProps as DayPickerRangeProps)}
          mode="range"
        />
      )}
    </div>
  );
};

export default KdDayPickerView;

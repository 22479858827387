import { StrictMode } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

import { AuthStateProvider } from '../auth/hooks/useAuth';
import NotFoundPage from '../pages/NotFound/NotFoundPage';
import useRouterProvider from './useRouterProvider';

const RouterProvider = () => {
  const { authState, isRouteVisible, isStrictMode, pageTitle } =
    useRouterProvider();

  const pageContent = !isStrictMode ? (
    <Outlet context={{ pageTitle }} />
  ) : (
    <StrictMode>
      <Outlet context={{ pageTitle }} />
    </StrictMode>
  );

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AuthStateProvider value={authState}>
        {isRouteVisible ? pageContent : <NotFoundPage />}
      </AuthStateProvider>
    </>
  );
};

export default RouterProvider;

import i18n, { t } from '../../../i18n';
import { ReactComponent as analyticsHoverImgGrid } from '../../../theme/grid/images/dashboard/analytics.svg';
import { ReactComponent as orgHoverImgGrid } from '../../../theme/grid/images/dashboard/org.svg';
import { ReactComponent as usersHoverImgGrid } from '../../../theme/grid/images/dashboard/users.svg';
import { MenuItemsType } from '../types/menuTypes';
import { getMenuDashboardIcon, getMenuIcon } from './getMenuIcon';

export const menuMobileLA: MenuItemsType = [
    {
        title: t('Dashboard'),
        description: '',
        img: getMenuIcon('dashboard'),
        linkHref: '/dashboard',
        highlightLocations: ['dashboard'],
        testId: 'home-button',
        userType: 'LA',
        visibleOnDashboard: false,
    },
    {
        title: t('Analytics'),
        description: t('View the analytics for the loading bays in your area'),
        img: getMenuIcon('analytics'),
        imgDashboard: getMenuDashboardIcon('analytics'),
        linkHref: '/analytics-la',
        highlightLocations: ['analytics-la'],
        testId: 'analytics-button',
        hoverImage: analyticsHoverImgGrid,
        userType: 'LA',
    },
    {
        title: t('help'),
        description: t('Request for help'),
        img: getMenuIcon('help'),
        linkHref: '/help',
        highlightLocations: ['help'],
        testId: 'help-button',
        hoverImage: undefined,
        userType: 'LA',
        visibleOnDashboard: false,
    },
    {
        title: t('Manage Users'),
        description: t('Manage the users in your organisation and add new users'),
        img: getMenuIcon('users'),
        imgDashboard: getMenuDashboardIcon('users'),
        linkHref: '/manageusers',
        highlightLocations: ['manageusers', 'adduser'],
        testId: 'manage-users-button',
        hoverImage: usersHoverImgGrid,
        userType: 'LA',
    },
    {
        title: t('Edit Organisation'),
        description: t(
            'Edit your organisation, including addresses and contact details'
        ),
        img: getMenuIcon('organisation'),
        linkHref: '/editorganisation',
        highlightLocations: ['editorganisation'],
        testId: 'edit-organisation-button',
        hoverImage: orgHoverImgGrid,
        userType: 'LA',
        visibleOnDashboard: false,
    },
    {
        title: t('Documents'),
        description: t('View and download documents for your organisation'),
        img: '',
        linkHref: '/documents-la',
        highlightLocations: ['documents-la'],
        testId: 'documents-button',
        hoverImage: orgHoverImgGrid,
        hide: true,
        userType: 'LA',
    }
];

const menuItemsMobileLA: MenuItemsType = [];
const fill = () => {
    // reset array contents with fresh translated values
    menuItemsMobileLA.length = 0;
    menuItemsMobileLA.push(...menuMobileLA);
};

i18n.on('languageChanged init', () => {
    fill();
});

export default menuItemsMobileLA;

import { useCallback, useEffect, useRef, useState } from 'react';

import { KdDropdownType } from '../../../types/kdDropdown';

const useKdDropdown = ({
  defaultMenuValue,
  onChange,
  options,
  placeholder,
  value,
}: KdDropdownType) => {
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || '');

  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setShow(!show);
  };

  const handleSelectValue = (itemKey: string) => {
    setSelectedValue(itemKey);
    setShow(false);
    if (onChange) onChange(itemKey);
  };

  const handleClickOutside = useCallback(
    ({ target }: MouseEvent | KeyboardEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(target as Node) &&
        !buttonRef.current?.contains(target as Node) &&
        show
      ) {
        setShow(false);
      }
    },
    [setShow, show]
  );

  useEffect(() => {
    if (!show) return;

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside, show]);

  useEffect(() => {
    if (value) setSelectedValue(value);
  }, [value]);

  const foundedOptionLabel = options?.find(
    (option) => option.key === selectedValue
  )?.label;

  const visibleValue = (selectedValue && foundedOptionLabel) || placeholder;
  const buttonValue =
    selectedValue === defaultMenuValue ? placeholder : visibleValue;

  return {
    buttonRef,
    buttonValue,
    handleSelectValue,
    handleToggle,
    menuRef,
    selectedValue,
    show,
  };
};

export default useKdDropdown;

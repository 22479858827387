import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '../../../store';
import BackButton from './BackButton';
import HeaderContainer from './HeaderContainer';
import HeaderPageTitle from './HeaderPageTitle';
import Logo from './Logo';

type Props = {
  pageTitle: string;
};

const HeaderMobile: React.FC<Props> = ({ pageTitle }) => {
  const viewStore = useSelector((state: RootState) => state.viewStore);
  const location = useLocation();

  const HeaderButton = viewStore.headerButton;

  const ButtonHeader = viewStore.headerButton ? HeaderButton : <BackButton />;

  const isDashboard = location?.pathname === '/dashboard';

  return (
    <HeaderContainer
      variant="mobile"
      testId="topbar-mobile"
    >
      {isDashboard ? <Logo /> : <HeaderPageTitle pageTitle={pageTitle} />}
      {!isDashboard && ButtonHeader}
    </HeaderContainer>
  );
};

export default HeaderMobile;

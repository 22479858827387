import { useState } from 'react';

const useKdSorting = ({
  isSmallScreen,
  onFilterChange,
  defaultMenuValue,
}: {
  isSmallScreen?: boolean;
  onFilterChange?: (value: string) => void;
  defaultMenuValue?: string;
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultMenuValue || '');

  const handleFilterChange = (key: string) => {
    if (isSmallScreen) setSelectedValue(key);
    if (onFilterChange) onFilterChange(key);
  };

  return { handleFilterChange, selectedValue };
};
export default useKdSorting;

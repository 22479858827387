import { ExpandLess } from '@mui/icons-material';

import InitialsBox from '../../../pages/ManageUsers/ui/InitialsBox';
import { SvgIconType } from '../../../types/common';
import useHeaderDesktop from '../hooks/useHeaderDesktop';
import DropdownItem from './DropdownItem';
import HeaderContainer from './HeaderContainer';
import HeaderPageTitle from './HeaderPageTitle';
import Logo from './Logo';

export type TopbarButtonType = {
  title: string;
  path?: string;
  icon: SvgIconType;
  onClick?: Function;
  size?: string;
};

const HeaderDesktop = () => {
  const {
    menuItems,
    selectedIndex,
    setSelectedIndex,
    handleClick,
    user,
    show,
    clickOutsideRef,
  } = useHeaderDesktop();

  return (
    <HeaderContainer testId="topbar">
      <div className="flex items-center max-w-[500px]">
        <Logo />
        <HeaderPageTitle
          className="ml-[30px] 2xl:ml-[40px]"
          pageTitle={user?.organisation?.name ?? ''}
        />
      </div>

      <div className="relative flex justify-center items-center h-full mr-[40px]">
        <div
          className="flex items-center cursor-pointer"
          role="button"
          tabIndex={0}
          onClick={handleClick}
          data-testid="topbar-dropdown-menu"
          ref={clickOutsideRef}
        >
          <div className="flex mr-[15px]">
            <InitialsBox
              index={1}
              userName={user?.name!}
              size="topbar"
            />
          </div>
          <ExpandLess
            fontSize="large"
            color="inherit"
            style={{
              transform: show ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'all',
              transitionDuration: '200ms',
              transitionTimingFunction: 'ease-in-out',
            }}
          />

          {show && (
            <div className="absolute right-[9px] top-[calc(100%+10px)] flex flex-col w-[172px] border-[2px] border-borderColor bg-controlBg z-50 p-[10px] rounded-lg gap-[10px]">
              {menuItems.map((e, i) => (
                <DropdownItem
                  key={e.title}
                  button={e}
                  isSelected={selectedIndex === i}
                  onHandleClick={() => {
                    setSelectedIndex(i);
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </HeaderContainer>
  );
};

export default HeaderDesktop;

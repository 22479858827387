import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import cn from 'classnames';
import React from 'react';
import { CustomArrowProps } from 'react-slick';

interface Props extends CustomArrowProps {
  arrowClassName?: string;
  variant?: 'next' | 'prev';
}

const iconClasses = 'w-[40px] h-[40px]';

const KdCarouselArrow: React.FC<Props> = ({
  arrowClassName,
  className,
  currentSlide,
  onClick,
  slideCount,
  variant = 'prev',
  ...props
}) => {
  const isNext = variant === 'next';

  const classes = cn(
    className,
    'absolute top-[50%] -translate-y-2/4 text-borderColor',
    isNext ? 'right-[-25px]' : 'left-[-25px]',
    arrowClassName
  );

  return (
    <button
      className={classes}
      onClick={onClick}
      type="button"
      {...props}
    >
      {isNext ? (
        <ChevronRightIcon
          className={iconClasses}
          color="inherit"
        />
      ) : (
        <ChevronLeftIcon
          className={iconClasses}
          color="inherit"
        />
      )}
    </button>
  );
};

export default KdCarouselArrow;

import { format } from 'date-fns';

import { BayInfoType } from '../types/bayInfo';
import { DriverType } from '../types/driver';
import { startEndBookingSlotType } from '../types/startEndBookingSlot';
import { CreateBookingStoreActionType } from '../types/storeActions';
import { VehicleType } from '../types/vehicle';
import { t } from './../i18n';

export type createBookingStoreType = {
  selectedBay?: BayInfoType;
  createBookingTime: string;
  selectedDriver: DriverType | null;
  bookingDate: string;
  bookingVRM?: VehicleType;
  createBookingMode: 'create' | 'amend';
  mobileBayInfoMenuOpen: boolean;
  startEndBookingSlot: startEndBookingSlotType;
};

const initialState: createBookingStoreType = {
  selectedBay: undefined,
  createBookingTime: t('Select a Booking Time'),
  selectedDriver: null,
  bookingDate: `${format(new Date(), 'yyyy-MM-dd')}`,
  bookingVRM: undefined,
  createBookingMode: 'create',
  mobileBayInfoMenuOpen: false,
  startEndBookingSlot: {
    bookingStartTime: null,
    bookingEndTime: null,
    numberOfSlotsBooked: 0,
  },
};

export const initialCreateBookingStoreState: createBookingStoreType = {
  selectedBay: undefined,
  createBookingTime: t('Select a Booking Time'),
  selectedDriver: null,
  bookingDate: `${format(new Date(), 'yyyy-MM-dd')}`,
  bookingVRM: undefined,
  createBookingMode: 'create',
  mobileBayInfoMenuOpen: false,
  startEndBookingSlot: {
    bookingStartTime: null,
    bookingEndTime: null,
    numberOfSlotsBooked: 0,
  },
};

const createBookingStore = (
  state = initialState,
  action: CreateBookingStoreActionType
) => {
  switch (action.type) {
    case 'SET_SELECTED_BAY':
      return {
        ...state,
        selectedBay: action.payload,
      };
    case 'SET_CREATE_BOOKING_TIME':
      return {
        ...state,
        createBookingTime: action.payload,
      };
    case 'SET_SELECTED_DRIVER':
      return {
        ...state,
        selectedDriver: action.payload,
      };
    case 'SET_BOOKING_DATE':
      return {
        ...state,
        bookingDate: action.payload,
      };
    case 'SET_BOOKING_VRM':
      return {
        ...state,
        bookingVRM: action.payload,
      };
    case 'SET_CREATE_BOOKING_MODE':
      return {
        ...state,
        createBookingMode: action.payload,
      };
    case 'SET_MOBILE_BAY_INFO_MENU_OPEN':
      return {
        ...state,
        mobileBayInfoMenuOpen: action.payload,
      };
    case 'SET_START_END_BOOKING_SLOT':
      return {
        ...state,
        startEndBookingSlot: action.payload,
      };
    default:
      return state;
  }
};
export default createBookingStore;

import cn from 'classnames';

interface Props extends React.HTMLProps<HTMLButtonElement> {
  selected: boolean;
  icon: React.ReactNode;
  title?: string;
  testId?: string;
}

const MenuItem = ({
  className,
  icon,
  selected,
  title,
  testId,
  ...props
}: Props) => {
  const classes = cn(
    'firstChild:mx-auto',
    className,
    selected ? 'text-white' : 'text-gray-300'
  );

  return (
    <button
      {...props}
      className={classes}
      data-testid={testId}
      title={title}
      type="button"
    >
      {icon}
    </button>
  );
};

export default MenuItem;

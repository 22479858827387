import { Dispatch, useCallback, useEffect, useState } from 'react';

import useHandleApiErrorRedirect from './useHandleApiErrorRedirect';
import useHandleToast from './useHandleToast';

type UseApiCallOptions<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiCall?: (args: any) => Promise<T>;
  apiArgs?: { [key: string]: unknown };
  dependencies?: Array<unknown>;
  isToastErrorMessage?: boolean;
  autoCall?: boolean;
  onSuccess?: (response?: T) => void;
  onErrorGoBack?: boolean;
};

type UseApiCallResult<T> = {
  error?: Error;
  fetchData?: VoidFunction;
  loading?: boolean;
  response: T | null;
  setResponse?: Dispatch<T>;
};

/**
 * A Custom Hook that calls an api from a component and exposes an array containing 'loading and response'
 * @param {Function} apiCall - apiCall fetch method.
 * @param {Array} argsArray  - args to be called with apicall. Defaults to empty array.
 * @param {ANY} dependancy - dependancy of api call. Defaults to null
 * @param {Boolean} isToastErrorMessage - if true, error will be displayed in global error message. Defaults to true.
 * @param {Boolean} autoCall - if true, apiCall will be called on mount. Defaults to true.
 * @param {Function} onSuccess - onSuccess callback
 * @param {Boolean} onErrorGoBack - on API failure will move user back using goBack
 * @return {Array} [Loading: boolean, Response: Any, Error: Error, fetchData: () => void]
 */

const useCallApi = <T>({
  apiCall,
  apiArgs = {},
  autoCall = true,
  dependencies = [],
  isToastErrorMessage = true,
  onErrorGoBack = false,
  onSuccess,
}: UseApiCallOptions<T>): UseApiCallResult<T> => {
  const { apiErrorRedirect } = useHandleApiErrorRedirect();
  const [response, setResponse] = useState<T | null>(null);
  const [loading, setLoading] = useState(autoCall);
  const [error, setError] = useState<Error>();

  const { handleToast } = useHandleToast();

  const fetchData = useCallback(() => {
    setLoading(true);
    setError(undefined);
    apiCall?.(apiArgs)
      .then((responseData: T) => {
        setResponse(responseData);
        if (onSuccess) onSuccess(responseData);
      })
      .catch((err: Error) => {
        setError(err);
        if (onErrorGoBack) {
          apiErrorRedirect(err);
        }
        if (isToastErrorMessage) {
          handleToast({
            variant: 'error',
            content: err?.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCall, apiArgs, isToastErrorMessage]);

  useEffect(() => {
    if (autoCall) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  return { error, fetchData, loading, response, setResponse };
};

export default useCallApi;

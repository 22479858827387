import { useMemo } from 'react';
import { useAuth } from '../../../auth/hooks/useAuth';
import menuItemsCF from '../data/menuItemsCF';
import menuItemsLA from '../data/menuItemsLA';
import { getActiveMenuItems } from '../functions/getFilteredMenuItems';
import { MenuItemsType } from '../types/menuTypes';
import menuItemsMobileCF from '../data/menuItemsMobileCF';
import menuItemsMobileLA from '../data/menuItemsMobileLA';
import useWindowDimensions from "../../../hooks/useWindowDimensions";

interface UseActiveMenuItemsResult {
  menuItems: MenuItemsType;
}

const useActiveMenuItems = (): UseActiveMenuItemsResult => {
  const { userType } = useAuth();
  const windowDimensions = useWindowDimensions();

  let initialMenuItems: MenuItemsType;

  if (windowDimensions.isMobile) {
    initialMenuItems = userType === 'CF' ? menuItemsMobileCF : menuItemsMobileLA;
  } else {
    initialMenuItems = userType === 'CF' ? menuItemsCF : menuItemsLA;
  }

  const menuItems = useMemo(
      () => getActiveMenuItems(initialMenuItems),
      [initialMenuItems]
  );

  return { menuItems };
};

export default useActiveMenuItems;

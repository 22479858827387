const useInitialsBox = ({ index }: { index: number }) => {
  const headerIconBackgroundColor = '#B5F5E9';

  const headerIconTextColor = '#81B1A8';

  const arrayOfBgColors = [
    '#FFA2A2',
    headerIconBackgroundColor,
    '#FFC65C',
    '#7661EA',
    '#A9F7A6',
    '#CCA4F4',
    '#84AFEA',
    '#23AA80',
    '#DEEA84',
    '#EA84A3',
  ];

  const arrayOfTextColor = [
    '#B96B6B',
    headerIconTextColor,
    '#AA843D',
    '#4C3E98',
    '#6D9F6B',
    '#9074AD',
    '#5D7CA7',
    '#1C8162',
    '#9AA25C',
    '#A25B71',
  ];

  const getInitials = (userName: string) => {
    let initials = 'User';
    if (userName && userName.length > 1) {
      const nameSplit = userName?.toUpperCase()?.split(' ');
      const firstName = nameSplit[0] ?? 'X';
      const lastName = nameSplit[1] ?? 'X';
      initials =
        nameSplit.length > 1
          ? `${firstName[0]}${lastName[0]}`
          : `${firstName[0]}${firstName[1]}`;
    }
    return initials;
  };

  const cardColor = arrayOfBgColors[index % arrayOfBgColors.length];
  const cardTextColor = arrayOfTextColor[index % arrayOfTextColor.length];

  return { cardColor, cardTextColor, getInitials };
};
export default useInitialsBox;

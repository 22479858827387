export const networkErrorMessage = 'Server can`t be reached';
export const externalErrorMessage = 'Unexpected error, please try again later';

export const getApiErrorMessage = (status: number | string) => {
  switch (status) {
    case 400:
      return 'Parameters are incorrect';
    case 401:
      return 'Unauthorized';
    case 403:
      return 'You don`t have permissions to perform this action';
    case 404:
      return 'The request is not found';
    case 'network':
      return networkErrorMessage;
    default:
      return externalErrorMessage;
  }
};

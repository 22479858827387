import { useAppDispatch } from '../useAppDispatch';

type Props = {
  content: string;
  noTopBar?: boolean;
  title?: string;
  variant?: 'success' | 'error';
};

const useHandleToast = () => {
  const dispatch = useAppDispatch();

  const handleToast = ({
    content,
    noTopBar,
    title,
    variant = 'error',
  }: Props) => {
    dispatch({
      type: 'SET_TOAST_DATA',
      payload: {
        content,
        variant,
        isDisplayed: true,
        title,
        noTopBar,
      },
    });
  };

  return { handleToast };
};
export default useHandleToast;

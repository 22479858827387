import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useRedirect = () => {
  const [prevPath, setPrevPath] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const redirectTo = (path: string) => {
    const currentPath = location?.pathname ?? '';

    if (currentPath !== path) {
      console.info(`Redirecting ${currentPath} to ${path}`);
      navigate(path);
    } else if (prevPath !== path) {
      setPrevPath(path);
      navigate(path);
    }
  };

  const goForward = () => {
    navigate(1);
  };

  const goBack = () => {
    navigate(-1);
  };

  return {
    redirectTo,
    goBack,
    goForward,
    pathname: location?.pathname,
  };
};

export default useRedirect;

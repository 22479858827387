import { SchemaFiles, SchemaInfo } from '../../../types/schema';
import useLogo from '../hooks/useLogo';

const schemaFiles: SchemaFiles =
  require(`../../../theme/${process.env.REACT_APP_THEME}/schemaFiles.tsx`).default;
const schemaInfo: SchemaInfo = require(`../../../theme/${process.env.REACT_APP_THEME}/schemaInfo.json`);

const Logo = () => {
  const { logoUrl } = useLogo();

  return (
    <a href={logoUrl}>
      <schemaFiles.images.primaryLogo
        alt={schemaInfo.productName}
        className="max-w-[125px] h-[32px]"
      />
    </a>
  );
};

export default Logo;

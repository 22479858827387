export enum VehicleTypesEnum {
  ALL = 'ALL',
  MOTOR_VEHICLE = 'MOTOR_VEHICLE',
  CARGO_BIKE = 'CARGO_BIKE',
}

export enum FuelTypesEnum {
  DIESEL = 'DIESEL',
  ELECTRICITY = 'ELECTRICITY',
  HYBRID_ELECTRIC = 'HYBRID ELECTRIC',
  PETROL = 'PETROL',
}

export enum UserTypeEnum {
  Driver = 'Driver',
  Admin = 'Admin',
  LA_Admin = 'LA-Admin',
}

export enum DataDogEnviromentNamesEnum {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

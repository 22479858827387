import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { t } from 'i18next';
import React, {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { callGetUser } from '../../api/kerb';
import { resetSiteData } from '../../auth/functions/resetSiteData';
import { verifyCacheOwnership } from '../../functions/memoryCache';
import { handleCognitoNameAssignment } from '../../functions/utils';
import useHandleToast from '../../hooks/useHandleToast';
import useRedirect from '../../hooks/useRedirect';
import { UserType } from '../../types/common';
import { AuthContextType } from '../types/authContextType';
import { AuthState } from '../types/authState';
import { SignUpData } from '../types/signUpData';
import { UiState } from '../types/uiState';
import { DbOrganisation, DbUser, UserDetails } from '../types/userDetails';
import { AwsInstance } from './awsManager';

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  userType: 'CF',
  user: null,
  userGroup: '',
  uiState: {},
  tempName: null,
  isUserRestoreComplete: false,
  restorePathName: null,
});

const awsInstance = AwsInstance.getInstance();
export const AuthStateProvider = ({
  value,
  children,
}: {
  value: AuthContextType;
  children: ReactNode;
}) => {
  const [state, setState] = useState<AuthState>(value);
  const [aws] = useState<AwsInstance>(awsInstance);
  const { pathname } = useRedirect();
  const [originalPath, setOriginalPath] = useState<string>();

  const { handleToast } = useHandleToast();
  const { redirectTo } = useRedirect();

  const prevStateRef = useRef<AuthState>();

  useEffect(() => {
    if (state.isUserRestoreComplete && pathname === '/') {
      setState((prevState) => ({ ...prevState, isUserRestoreComplete: false }));
      setTimeout(() => {
        authRedirect();
      }, 100);
    }
    prevStateRef.current = state;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const init = async () => {
      setOriginalPath(pathname);
      await aws.setEventHandlers({
        signInHandler,
        signOutHandler,
        restoreHandler,
        restoreErrorHandler,
      });

      const savedUserType = localStorage.getItem('userType') as 'CF' | 'LA';

      setState((prevState) => ({
        ...prevState,
        userType: savedUserType,
        isUserRestoreComplete: false,
      }));
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signInHandler: (challenge: string) => void = useCallback(
    async (challenge: string) => {
      if (challenge === 'NEW_PASSWORD_REQUIRED') {
        redirectTo('/newpassword');
        return;
      }
      redirectTo('/');
    },
    [redirectTo]
  );

  const signOutHandler: () => void = () => {
    setState((prevState) => {
      prevState.isLoggedIn = false;
      prevState.tempName = null;
      prevState.user = null;
      prevState.userGroup = '';
      prevState.uiState = {};
      prevState.error = null;
      return prevState;
    });
    redirectTo('/');
  };

  const restoreHandler: () => void = async () => {};

  const restoreErrorHandler: (error: unknown) => void = () => {
    redirectTo('/');
  };

  const setUserType = useCallback(
    (userType: UserType) => {
      if (state.userType === userType) return;
      aws.resetByUserType(userType);
      localStorage.setItem('userType', userType);
      setState((prevState) => ({ ...prevState, userType }));
    },
    [aws, state.userType]
  );

  const setUserDetails = (userDetails: Partial<UserDetails>) => {
    setState((prevState) => ({
      ...prevState,
      user: {
        ...prevState?.user,
        ...userDetails,
        organisation: prevState?.user?.organisation as DbOrganisation,
      } as DbUser,
    }));
  };

  const setOrganisation = (organisation: Partial<DbOrganisation>) => {
    setState((prevState) => ({
      ...prevState,
      user: {
        ...prevState?.user,
        organisation: {
          ...prevState?.user?.organisation,
          ...organisation,
        },
      } as DbUser,
    }));
  };

  const setUiState = useCallback((uiState: UiState) => {
    localStorage.setItem('uiState', JSON.stringify(uiState));
    setState((prevState) => ({ ...prevState, uiState }));
  }, []);

  const setUser = (user: DbUser) => {
    setState((prevState) => ({ ...prevState, user }));
  };

  const setTempName = (tempName: string) => {
    setState((prevState) => ({ ...prevState, tempName }));
  };

  const assertLoggedOutUserType = useCallback(
    async (expectedUserType: UserType) => {
      if (state.userType !== expectedUserType) {
        redirectTo('/');
      }
    },
    [redirectTo, state.userType]
  );

  const assertUserLogin = useCallback(() => {
    if (!aws?.isLoggedIn()) {
      redirectTo('/');
    }
  }, [aws, redirectTo]);

  const setError = useCallback(
    (error: string) => {
      if (state.error === error) return;
      setState((prevState) => ({ ...prevState, error: error }));
    },
    [state.error]
  );

  const getAuth = () => Auth;

  const handleSignInError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (err: any, username: string) => {
      if (
        err?.code === 'UserNotFoundException' ||
        err?.code === 'NotAuthorizedException'
      ) {
        setError(t('Incorrect username or password'));
        return;
      }

      if (err?.code === 'UserNotConfirmedException') {
        /* 
          PLEASE DO NOT REMOVE THE FOLLOWING COMMENT
          When a user is not confirmed, even though we let the sign go through, we DO NOT show an error message 
          and redirect the user to the confirmation page, However, if another user has already been signed in, 
          then during the authRedirect, call to restoreUserDetails will restore the old user session. 
          
          In fact, for a not-confirmed user, the login is not a real login because it faces a UserNotConfirmedException. 
          
          To prevent this, we want to reset site data in this case just to make the old successful sign-in invalid. 
          However, we do not want to reset the user type (that leads to a redirect to the landing page), or reload 
          the page (that will redirect to the login page again).

          The callLogout parameter is set to false because we do not want to log out the old user by calling the AWS
          client which in turn calls the signOutHandler, instead, we clear everything except the user type.
          
          That is why we call resetSiteData like this:
        */
        const email: string = username;
        resetSiteData({
          callLogout: false,
          preserveUserType: true,
          reload: false,
        });
        setUiState({ ...state.uiState, email });
        redirectTo('/confirm');
        return;
      }

      handleToast({
        content: err?.message ?? err ?? t('Unknown Error'),
        noTopBar: true,
      });
    },
    [handleToast, redirectTo, setError, setUiState, state.uiState]
  );

  const signUp = useCallback(async (data: SignUpData) => {
    const fullName = data?.firstName + ' ' + data?.lastName;
    await getAuth().signUp({
      username: data?.username.toLowerCase(),
      password: data?.password,
      attributes: {
        name: fullName,
        address: '',
        email: data?.username.toLowerCase(),
      },
      validationData: [],
    });
  }, []);

  const setTempValue = useCallback(
    (value: string) => {
      aws.setTempValue(value);
    },
    [aws]
  );

  const signIn = useCallback(
    async (username: string, password: string) => {
      try {
        const cognitoUser = await getAuth().signIn(username, password);
        const name = handleCognitoNameAssignment({ cognitoUser, username });
        setTempName(name);
        // if the AWS  doesn't throw an error and returns a user, then the user challenge should be checked.
        if (cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUiState({
            ...state.uiState,
            email: username,
          });
          setTempValue(password);
          signInHandler(cognitoUser.challengeName);
        }
      } catch (error) {
        console.error('Error during sign in:', error);
        handleSignInError(error, username);
      }
    },
    [handleSignInError, setTempValue, setUiState, signInHandler, state.uiState]
  );

  const completeNewPassword = useCallback(
    async (email: string, oldPassword: string, newPassword: string) => {
      const cognitoUser = await getAuth().signIn(email, oldPassword);
      signIn(email, oldPassword)
        .then(() => {
          getAuth()
            .completeNewPassword(cognitoUser, newPassword)
            .catch((err) => {
              handleToast({
                content: err?.message,
                noTopBar: true,
              });
            });
        })
        .catch((err) => {
          handleToast({
            content: err?.message,
            noTopBar: true,
          });
        })
        .finally(() => redirectTo('/'));
    },
    [handleToast, redirectTo, signIn]
  );

  const confirmSignup = useCallback(async (username: string, code: string) => {
    await getAuth().confirmSignUp(username, code, {
      forceAliasCreation: true,
    });
  }, []);

  const resendSignUp = useCallback(async (username: string) => {
    await getAuth().resendSignUp(username);
  }, []);

  const forgotPassword = useCallback(async (username: string) => {
    await getAuth().forgotPassword(username);
  }, []);

  const forgotPasswordSubmit = useCallback(
    async (username: string, confirmCode: string, newPassword: string) => {
      await getAuth().forgotPasswordSubmit(username, confirmCode, newPassword);
    },
    []
  );

  const signOut = useCallback(async () => {
    const savedUserType = localStorage.getItem('userType') as 'CF' | 'LA';
    await getAuth()
      ?.signOut({ global: true })
      .catch(() => {
        resetSiteData({});
      })
      .finally(() => {
        setState(() => ({
          isLoggedIn: false,
          userType: savedUserType,
          user: null,
          userGroup: '',
          uiState: {},
          tempName: null,
          isUserRestoreComplete: false,
          restorePathName: '',
        }));
        localStorage.setItem('uiState', JSON.stringify({}));
        redirectTo('/');
      });
  }, [redirectTo]);

  const getTempValue = useCallback((): string => aws.getTempValue(), [aws]);

  const clearAllCookies = () => {
    document.cookie = '';
  };

  const restoreUserDetails = useCallback(async (): Promise<
    Partial<AuthState>
  > => {
    try {
      await aws.restoreSession();
      const cognitoUser = aws?.getCognitoUser();
      const session = aws?.getCognitoSession();
      const idToken = session?.getIdToken();
      const userGroup: string =
        (idToken?.payload['cognito:groups'][0] as string) ?? '';

      if (
        (state.userType === 'CF' && userGroup !== 'Admin') ||
        (state.userType === 'LA' && userGroup !== 'LA-Admin') ||
        (state.userType !== 'CF' && state.userType !== 'LA')
      ) {
        handleToast({
          variant: 'error',
          content: 'Please contact system administrator',
          noTopBar: true,
        });

        throw new Error('Permission Denied');
      }

      const savedUiState = JSON.parse(localStorage.getItem('uiState') ?? '{}');

      const uiState: UiState = {
        isResettingPassword: false,
        confirmationCode: null,
        securityCode: null,
        ...savedUiState,
        ...state?.uiState,
      };

      const dbUser: DbUser | string | null = await getDatabaseUser(
        cognitoUser as CognitoUser
      );

      if (
        dbUser === null ||
        (typeof dbUser === 'string' && dbUser !== 'Not found')
      ) {
        handleToast({
          noTopBar: true,
          variant: 'error',
          title: 'Something went wrong!',
          content: 'If this issue persists contact your account administrator',
        });

        throw new Error('Unexpected API failure');
      }

      verifyCacheOwnership((dbUser as DbUser)?.organisation?.id as string);

      const newPartialState = {
        userGroup: userGroup,
        uiState,
        user: dbUser,
        isLoggedIn: !!cognitoUser,
        isUserRestoreComplete: true,
        restorePathName: originalPath,
      } as AuthState;
      setState(
        (prevState) => ({ ...prevState, ...newPartialState }) as AuthState
      );
      return newPartialState;
    } catch (e) {
      setState(
        (prevState) =>
          ({
            ...prevState,
            isUserRestoreComplete: true,
            restorePathName: originalPath,
          }) as AuthState
      );
      return {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aws, handleToast, originalPath, state?.uiState, state.userType]);

  const getDatabaseUser = useCallback(
    async (user: CognitoUser): Promise<DbUser | null | string> => {
      const userName = user?.getUsername();

      if (!userName) return null;

      const result = await callGetUser({ id: userName }).catch((e) => e);

      if (result?.message) return result?.message;

      if (!result.id) return null;
      return result;
    },
    []
  );

  const authRedirect = useCallback(async () => {
    if (state?.isLoggedIn) {
      if (state?.user?.organisation) {
        setState((prevState) => ({ ...prevState, restorePathName: null }));
        const excludedUrls = ['/login', '/login-la', '/landing', '/', '/error'];
        if (
          !excludedUrls.includes(state.restorePathName as string) &&
          state.restorePathName &&
          pathname !== state.restorePathName
        ) {
          redirectTo(state.restorePathName);
        } else {
          redirectTo('/dashboard');
        }
      } else {
        redirectTo('/addorganisation');
        handleToast({
          variant: 'success',
          content: 'Please create your organisation',
          noTopBar: true,
          title: 'Successful first login',
        });
      }
    } else {
      if (state.userType === 'CF') {
        redirectTo('/login');
      } else if (state.userType === 'LA') {
        redirectTo('/login-la');
      } else {
        redirectTo('/landing');
      }
    }
  }, [
    handleToast,
    pathname,
    redirectTo,
    state?.isLoggedIn,
    state.restorePathName,
    state?.user?.organisation,
    state.userType,
  ]);

  const returnValue = useMemo(
    () => ({
      ...state,
      isLoggedIn: !!aws?.isLoggedIn(),
      restorePathName: state.restorePathName,

      setUserType,
      setUserDetails,
      setUiState,
      setUser,
      setOrganisation,
      assertLoggedOutUserType,
      assertUserLogin,
      setError,
      setTempValue,
      getTempValue,

      signIn,
      completeNewPassword,
      signUp,
      confirmSignup,
      resendSignUp,
      forgotPassword,
      forgotPasswordSubmit,
      signOut,
      clearAllCookies,
      restoreUserDetails,
      authRedirect,
      signInHandler,
      getDatabaseUser,
    }),
    [
      assertLoggedOutUserType,
      assertUserLogin,
      aws,
      completeNewPassword,
      confirmSignup,
      forgotPassword,
      forgotPasswordSubmit,
      getTempValue,
      resendSignUp,
      restoreUserDetails,
      setError,
      setTempValue,
      setUiState,
      setUserType,
      signIn,
      signOut,
      signUp,
      authRedirect,
      signInHandler,
      state,
      getDatabaseUser,
    ]
  );

  return (
    <AuthContext.Provider value={returnValue as AuthContextType}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);

import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { getMenuItemWidth } from '../functions/getMenuItemWidth';
import { MenuItemsType, SelectedHighlightType } from '../types/menuTypes';

const useMenuMobile = ({
  menuItems,
  selectedHighlight,
}: {
  menuItems: MenuItemsType;
  selectedHighlight: SelectedHighlightType;
}) => {
  const [isHighlightMore, setIsHighlightMore] = useState(false);

  const { width } = useWindowDimensions();
  const location = useLocation();

  const handleMoreClick = () => {
    setIsHighlightMore(true);
  };

  const itemWidth = getMenuItemWidth(menuItems, width);
  const isAboutPage = location.pathname === '/about';

  const isMoreButtonActive =
    selectedHighlight.upper > 4 || isHighlightMore || isAboutPage;

  return { isMoreButtonActive, handleMoreClick, itemWidth, isHighlightMore };
};
export default useMenuMobile;

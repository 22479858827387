import { t } from 'i18next';
import React from 'react';

import KdButton from '../../shared/KdButton';
import useNotFoundPage from './hooks/useNotFoundPage';

const NotFoundPage: React.FC = () => {
  const { handleReturnHome } = useNotFoundPage();

  return (
    <main className="flex justify-center items-center px-[30px] h-[100vh]">
      <div className="text-center">
        <p className="text-[28px] text-content mb-[30px] font-[700]">
          {t('Page not found')}
        </p>
        <p className="text-[16px] sm:text-[20px] text-content mb-[30px] sm:max-w-[460px] leading-6">
          {t(
            'The page you are looking for could not be found. Check your URL or return to the homepage.'
          )}
        </p>
        <KdButton
          testId="button-return-home"
          onClick={handleReturnHome}
          fullOnMobile
        >
          {t('Return Home')}
        </KdButton>
      </div>
    </main>
  );
};

export default NotFoundPage;

import { getRequest, patchRequest, postRequest, rejectRequest } from '../fetch';
import {
  CreateOrganisationRequestType,
  UpdateOrganisationRequestType,
} from '../types';
import { updateOrganisationScheme } from '../validation';

export const callCreateRegistration = ({
  data,
}: CreateOrganisationRequestType) => postRequest('/registration', data);

export const callPatchOrganisation = ({
  id,
  data,
}: UpdateOrganisationRequestType) => {
  const { error } = updateOrganisationScheme.validate({ id });

  if (error) {
    return rejectRequest(error?.message);
  }

  return patchRequest(`/organisations/${id}`, data);
};

export const callGetSectors = () => getRequest('/sectors');

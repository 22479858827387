import cn from 'classnames';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import UpdateChecker from '../UpdateChecker';

interface Props {
  checkForUpdates?: boolean;
  children: ReactNode;
  className?: string;
  noBackground?: boolean;
  noPadding?: boolean;
}

const PrivatePage = ({
  checkForUpdates,
  children,
  className,
  noBackground,
  noPadding,
}: Props) => {
  const location = useLocation();
  const { isMobile } = useWindowDimensions();

  const isDashboard = location?.pathname?.includes('/dashboard');
  const isDashboardBg = isMobile && isDashboard;

  const classes = cn(
    isMobile
      ? 'h-[calc(100vh-(theme(height.mobileHeader)+theme(height.mobileMenu)))]'
      : 'h-[calc(100vh-theme(height.header))] ml-[theme(width.menu)]',
    {
      'p-[30px] sm:pt-[60px] sm:pb-0': !noPadding,
      'bg-accentSecondary': noBackground,
      'bg-no-repeat bg-[length:100%]': isDashboardBg,
    },
    'overflow-y-auto animate-fade-in-down',
    className
  );

  const dashboardBgImage = `url("/curve-grid.svg")`;

  return (
    <>
      <main
        className={classes}
        style={
          isDashboardBg ? { backgroundImage: dashboardBgImage } : undefined
        }
      >
        {children}
      </main>
      {checkForUpdates && <UpdateChecker />}
    </>
  );
};

export default PrivatePage;

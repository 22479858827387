import * as RR from 'react-redux';
import { Dispatch } from 'redux';

import {
  CreateBookingStoreActionType,
  ManageBookingsStoreActionType,
  ManageUserStoreActionType,
  ManageVehicleStoreActionType,
  ViewStoreActionType,
} from './types/storeActions';

type ReduxStoreActions =
  | CreateBookingStoreActionType
  | ViewStoreActionType
  | ManageBookingsStoreActionType
  | ManageUserStoreActionType
  | ManageVehicleStoreActionType
  | { type: 'RESET_STORE' }
  | {
      type: 'SET_FEATURE_FLAG_STATUS';
      payload: { [key: string]: string | boolean };
    };

export const useAppDispatch = () =>
  RR.useDispatch<Dispatch<ReduxStoreActions>>();

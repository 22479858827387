import { t } from 'i18next';
import { useEffect } from 'react';

import { useAuth } from '../../auth/hooks/useAuth';
import LoadingCircle from '../../shared/LoadingCircle';

const HomePage = () => {
  const { restoreUserDetails } = useAuth();
  useEffect(() => {
    restoreUserDetails?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingCircle text={t('Getting User Details')} />;
};

export default HomePage;
